/* eslint-disable */

import React, { Component } from "react";
import {
  Popover,
  Col,
  Button,
  Dropdown,
  OverlayTrigger,
} from "react-bootstrap";
import {
  Tooltip,
  Button as Button2,
  CircularProgress,
  circularProgressClasses,
  MenuItem,
} from "@mui/material";
import { CSSTransition } from "react-transition-group";
import { config } from "../../constants";
import * as Icon from "react-feather";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import moment from "moment-timezone";
import WithRouter from "../../HOC/RouterWrapper";
import jwtDecode from "jwt-decode";
import WorkflowVisualiser from "./workflows/WorkflowVisualiser";
import _ from "lodash";
import { useAuth, useTenantsState } from "@frontegg/react";
import SearchJobs from "./SearchJobs";

import { ReactComponent as TimeIcon } from "../../assets/img/icons/workflow-time.svg";
import { ReactComponent as StateIcon } from "../../assets/img/icons/workflow-state.svg";
import { ReactComponent as RefreshIcon } from "../../assets/img/icons/refresh.svg";
import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import { ReactComponent as PausedIcon } from "../../assets/img/icons/pause.svg";
import { ReactComponent as StopIcon } from "../../assets/img/icons/StopIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/img/icons/CancelIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/icons/SettingsIcon.svg";
import { ReactComponent as PlayIcon } from "../../assets/img/icons/PlayIcon.svg";
import { ReactComponent as PausingIcon } from "../../assets/img/icons/PausingIcon.svg";
import { ReactComponent as OptionsIcon } from "../../assets/img/icons/OptionsIcon.svg";

import "../../styles/table.css";
import HorizontalDropdownMenu from "../../components/global/HorizontalDropdown";
import DagHistory from "./DagHistory";
import DagEdit from "./DagEdit";
import Table from "../../components/global/Table";
import TablePagination from "../../components/global/Table/Pagination";
import TableTooltip from "../../components/global/Table/Tooltip";
import {
  createWorkflowService,
  deleteWorkflowService,
  editWorkflowService,
  fetchDagsService,
  fetchVisualizationsService,
  rerunWorkflowService,
  stopDagService,
  togglePauseService,
  triggerWorkflowService,
} from "../../services/overview";
import DeleteConnectorModal from "../../components/Modals/DeleteConnector";
import CloseDrawerModal from "../../components/Modals/CloseDrawer";

Sentry.init({
  dsn: "https://53a82b56123b43348a6579efda968a77@o4504469493645312.ingest.sentry.io/4504469494497280",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const TenantContextComponent = (props) => {
  const { user } = useAuth();
  const { tenants } = useTenantsState();
  const activeTenant = tenants.find((t) => t.tenantId === user.tenantId);
  return <Overview {...props} activeTenant={activeTenant} />;
};

var customer_timezone = localStorage.getItem("timezone");

class Overview extends Component {
  constructor(props) {
    super(props);

    this.thRefs = Array(10)
      .fill()
      .map(() => React.createRef());
    this.debouncedHandleResize = _.debounce(this.handleResize, 300);

    this.state = {
      loading: false,
      historical_runs_available: false,
      EditOpen: false,
      DAGs: [],
      running_dags: [],
      loading_metadata: true,
      modal_delete_workflow: false,
      modal_lose_workflow_progress: false,
      dags_loading: [],
      creating_viz: false,
      loading_workflows: true,
      workflows_count: 0,
      workflows: [],
      dag_runs: [],
      last_succesful_runs: {},
      workflow_chain: [{ dependencies: [] }],
      historical_dag_runs: [],
      progress: 40,
      activeTab: "Workflows",
      selected_job: "",
      modal_edit: false,
      schedule_frequency: false,
      schedule_details: false,
      scheduling: false,
      modal_logs: false,
      schedule_day: false,
      creating_workflow: false,
      selected_job_logo: "",
      selected_job_type: "",
      active_dag_id_list: [],
      active_dag_data: {},
      active_dag_loading: false,
      active_dag_error_msg: null,
      active_dag_child_process_id_list: [],
      workflowsFilters: [],
      jobNameFilters: "",
      modal_workflow: false,
      modal_edit_workflow: false,
      new_workflows_chain: [],
      current_workflows_chain: [],
      isWorkflowBuilderShown: false,
      workflowBuilderType: "",
      dagsSorting: null, // {type, sorterSource}
      visulaizeHeaderText: "",
      shouldUpdateWorkflowStatuses: false,
      currentPage: 1,
      perPage: 10,
      isShowingDagHistoryTable: false,
      isShowingDagEdit: false,
      skips: false,
      sortedColumn: {
        column: "",
        isAsc: true,
      },
    };
  }

  setdbturl = () => {
    console.log("Setting DBT URL...");
    const localUrl = "http://127.0.0.1:5000";
    const sqlEditorUrl = config.url.SQL_EDITOR_URL;
    const waitForTenant = setInterval(() => {
      if (this.props.activeTenant?.name) {
        clearInterval(waitForTenant);
        const dbtUrl =
          sqlEditorUrl === localUrl
            ? localUrl
            : `${sqlEditorUrl}/${this.props.activeTenant.name}-dbt`;
        console.log("DBT URL:", dbtUrl);
        this.setState({ dbt_url: dbtUrl });
      }
    }, 500); // Check every 500ms
  };

  toggleHistoryDagTable = () => {
    this.setState({
      isShowingDagHistoryTable: !this.state.isShowingDagHistoryTable,
    });
  };

  handleResize = () => {
    setTimeout(() => {
      this.setState({
        thLogoWidth: document.getElementById("th-logo")
          ? document.getElementById("th-logo").offsetWidth
          : 0,
        thNameWidth: document.getElementById("th-name")
          ? document.getElementById("th-name").offsetWidth
          : 0,
        thWorkflowsWidth: document.getElementById("th-workflows")
          ? document.getElementById("th-workflows").offsetWidth
          : 0,
        thTypeWidth: document.getElementById("th-type")
          ? document.getElementById("th-type").offsetWidth
          : 0,
        thFrequencyWidth: document.getElementById("th-frequency")
          ? document.getElementById("th-frequency").offsetWidth
          : 0,
        thStartWidth: document.getElementById("th-start")
          ? document.getElementById("th-start").offsetWidth
          : 0,
        thEndWidth: document.getElementById("th-end")
          ? document.getElementById("th-end").offsetWidth
          : 0,
        thStatusWidth: document.getElementById("th-status")
          ? document.getElementById("th-status").offsetWidth
          : 0,
        thNextWidth: document.getElementById("th-next")
          ? document.getElementById("th-next").offsetWidth
          : 0,
        thActionsWidth: document.getElementById("th-actions")
          ? document.getElementById("th-actions").offsetWidth
          : 0,
      });
    }, 300);
  };

  detachObserver() {
    this.thRefs.forEach((ref, index) => {
      if (ref.current && this.resizeObservers[index]) {
        this.resizeObservers[index].unobserve(ref.current);
      }
    });
  }

  observeTableWidths() {
    if (!("ResizeObserver" in window)) {
      console.warn("Browser does not support ResizeObserver");
      return;
    }

    this.resizeObservers = this.thRefs.map((ref, index) => {
      if (ref.current) {
        const observer = new ResizeObserver(this.debouncedHandleResize);
        observer.observe(ref.current);
        return observer; // Store this observer instance to unobserve later
      }
      return null;
    });
  }

  componentDidMount() {
    const workflow_name = new URLSearchParams(window.location.search).get(
      "workflow"
    );

    const historyModal = new URLSearchParams(window.location.search).get(
      "history_modal"
    );

    const dagEdit = new URLSearchParams(window.location.search).get("dag_edit");

    if (workflow_name) {
      this.redirectToVisualization();
    } else {
      this.fetchTablesData().then(() => {
        if (historyModal && this.getTargetDAGData()) {
          this.toggleHistoryDagTable();
        }

        if (dagEdit) {
          const workflow = this.state.workflows.find(
            (item) => item.workflowname === dagEdit
          );

          this.setState({
            advanced_settings: workflow.advanced_settings,
          });
        }
      });
      this.observeTableWidths();
      this.setState({
        originalFetch: true,
        isShowingDagEdit: !!dagEdit,
        selected_edit_dag: dagEdit,
      });
    }
    this.setdbturl();
  }

  componentDidUpdate(prevProps, prevState) {
    const hasQueryParamsChanged =
      this?.props?.router?.location &&
      prevProps?.router?.location &&
      this.props.router.location.search !== prevProps.router.location.search;

    const hasInitialHistoryModalView =
      prevProps.router.location.search.includes("history_modal") ||
      this.props.router.location.search.includes("history_modal");

    const workflow_name = new URLSearchParams(window.location.search).get(
      "workflow"
    );
    const dagEdit = new URLSearchParams(window.location.search).get("dag_edit");

    const routedFromWorkflowVisualizer =
      !workflow_name &&
      prevProps.router.location.search.includes("workflow") &&
      !this.state.originalFetch;

    if (hasQueryParamsChanged) {
      if (hasInitialHistoryModalView) {
        this.showInitialHistoryModal(prevProps);
      }

      if (workflow_name) {
        this.redirectToVisualization();
      } else {
        this.setState({
          isWorkflowBuilderShown: false,
          current_workflows_chain: [],
        });
      }

      this.setState({
        isShowingDagEdit: !!dagEdit,
        selected_edit_dag: dagEdit,
      });

      if (routedFromWorkflowVisualizer) {
        this.fetchTablesData();
        this.observeTableWidths();
        this.setState({
          isWorkflowBuilderShown: false,
          current_workflows_chain: [],
          originalFetch: true,
        });
      }
    }
    if (!prevState.DAGs.length && this.state.DAGs.length) {
      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }
    }

    // setTimeout(() => {
    //   document.body.style.overflow = "";
    // }, 500);

    if (
      this.state.activeTab &&
      this.state.activeTab !== "Workflows" &&
      this.state.DAGs.length &&
      !this.state?.thNameWidth
    ) {
      this.observeTableWidths();
    }
  }

  redirectToHistoryModalView = (DAG) => {
    this.props.router.navigate(
      `/jobs/overview?history_modal=${DAG.workflowname}`
    );
  };

  redirectToVisualization = async () => {
    const workflow_name = new URLSearchParams(window.location.search).get(
      "workflow"
    );

    const workflow_type = new URLSearchParams(window.location.search).get(
      "workflow_type"
    );

    if (workflow_name && this.state.current_workflows_chain.length === 0) {
      this.visualiseFlow(workflow_type, workflow_name);
      await this.fetchInitialCrucialVisualizationData();
      this.setState({
        current_workflows_chain:
          this.state?.workflows?.find(
            (workflow) => workflow.workflowname === workflow_name
          )?.workflow_chain || [],
      });
    }
  };

  fetchInitialCrucialVisualizationData = async () => {
    this.setState({ workflows: [], loading_workflows: true });

    const response = await fetchVisualizationsService();
    let isRunning = false;

    if (response) {
      this.setState({
        workflows_count: response.data["workflows"].length,
        workflows: response.data["workflows"],
      });

      response.data["workflows"].forEach((workflow) => {
        if (
          workflow.state === "running" ||
          workflow.state === "queued" ||
          workflow.state === "Scheduling..."
        )
          isRunning = true;
      });

      if (isRunning && !this.pollingInterval) {
        this.pollingInterval = setInterval(() => {
          this.fetchDAGs(true);
        }, 10000); // 10 seconds interval
      } else if (!isRunning && this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    }

    this.setState({
      loading_metadata: true,
      progress: 100,
      loading: false,
      deleting: false,
      loading_workflows: false,
    });
  };

  getTargetDAGData = () => {
    const history_modal = new URLSearchParams(window.location.search).get(
      "history_modal"
    );
    const targetDAG = this.state.workflows.find(
      (DAG) => DAG.workflowname === history_modal
    );
    return targetDAG;
  };

  showInitialHistoryModal = (prevProps) => {
    //prevProps to extract queryParams from pervious render
    if (
      prevProps.router.location.search.includes("history_modal") &&
      !this.props.router.location.search.includes("history_modal")
    ) {
      this.setState({
        historical_runs_available: false,
        modal_history: false,
        isShowingDagHistoryTable: false,
      });
    }

    if (
      !prevProps.router.location.search.includes("history_modal") &&
      this.props.router.location.search.includes("history_modal")
    ) {
      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }
    }
  };

  fetchTablesData = async () => {
    this.setState({ progress: 70 });
    let height = document.documentElement.clientHeight;
    this.setState({ height: height * 0.7 });
    await this.fetchDAGs();

    if (this.state.active_dag_id_list.length) {
      this.state.active_dag_id_list.forEach((dag) => {
        this.refreshJobDetails(dag);
      });
    }
  };

  componentWillUnmount = () => {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
    this.detachObserver();
    this.debouncedHandleResize.cancel(); // Ensure debounce is cancelled on unmount
  };

  toggleEditModal = (DAG) => {
    this.setState({ selected_job: DAG, modal_edit: !this.state.modal_edit });
  };

  toggleDeleteWorkflow = (dag_name) => {
    this.setState({
      selected_dag: dag_name,
      modal_delete_workflow: !this.state.modal_delete_workflow,
    });
  };

  stopDAG = async (dag_alias) => {
    const dag = this.state.workflows?.find(
      (workflow) => workflow.workflowname === dag_alias
    );
    let dag_status = dag.state;
    let dag_id = dag.dag_id;

    if (dag_status !== "running" && dag_status !== "queued") {
      toast.info(dag_alias + " is not running.");
      return;
    }
    this.setState({ stopping: true });
    this.setState({ progress: 80 });
    await stopDagService(dag_id, dag_alias);
    this.setState({ progress: 100, stopping: false });
  };

  fetchDAGs = async (
    triggeredByInterval = false,
    triggeredByDagEdit = false
  ) => {
    if (!triggeredByInterval) {
      this.setState({ loading_workflows: true, workflows: [] });
    }
    const response = await fetchDagsService();

    if (response) {
      this.setState({
        workflows_count: response.data["workflows"].length,
        workflows: response.data["workflows"],
      });

      let isRunning = false;
      response.data["workflows"].forEach((workflow) => {
        if (
          workflow.state === "running" ||
          workflow.state === "queued" ||
          workflow.state === "Scheduling..."
        ) {
          console.log("Workflow is running, pinging again...");
          isRunning = true;
        }
      });

      if (isRunning && !this.pollingInterval) {
        this.pollingInterval = setInterval(() => {
          this.fetchDAGs(true);
        }, 10000); // 10 seconds interval
      } else if (!isRunning && this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }

      // **Add the following block to update `advanced_settings`**
      if (
        this.state.isShowingDagEdit &&
        this.state.selected_edit_dag &&
        triggeredByDagEdit
      ) {
        const updatedWorkflow = response.data["workflows"].find(
          (item) => item.workflowname === this.state.selected_edit_dag
        );
        if (updatedWorkflow) {
          this.setState({
            advanced_settings: updatedWorkflow.advanced_settings,
          });
        }
      }
    }

    this.setState({
      deleting: false,
      loading_workflows: false,
      progress: 100,
      loading: false,
    });
  };

  deleteWorkflow = async () => {
    this.setState({ deleting: true });

    const isSuccess = await deleteWorkflowService(this.state.selected_dag);

    if (isSuccess) {
      this.setState({ modal_delete_workflow: false });
      this.fetchDAGs(true);
    }
    this.setState({ deleting: false });
  };

  isTableLoading = () => {
    const isDagsRuns = typeof this.state?.dag_runs === "object";
    const dagsRunsLength = isDagsRuns
      ? Object.keys(this.state.dag_runs).length
      : 0;

    if (this.state?.activeTab === "Workflows") {
      return this.state.loading || this.state.loading_workflows;
    } else {
      return (
        this.state.loading ||
        (this.state?.DAGs?.length < 10
          ? dagsRunsLength !== this.state?.DAGs?.length
          : dagsRunsLength < 10)
      );
    }
  };

  triggerWorkflow = async (workflow_name) => {
    const isSuccess = await triggerWorkflowService(workflow_name);

    if (isSuccess) {
      if (this.state.isWorkflowBuilderShown) {
        this.setState({ shouldUpdateWorkflowStatuses: 10000 });
      }
      this.fetchDAGs(true);
    }
  };

  togglePause = async (workflowname, dag_id, paused_status) => {
    const isSuccess = await togglePauseService(
      workflowname,
      dag_id,
      !paused_status
    );

    if (isSuccess) this.fetchDAGs(true);
  };

  updateJobNameFilters = (value) => {
    this.setState(
      {
        jobNameFilters: value,
        currentPage: 1,
      },
      () => {}
    );
  };

  filterJobNames = (workflow) => {
    if (!this.state.jobNameFilters) {
      return true;
    }

    return workflow.workflowname
      .toLowerCase()
      .includes(this.state.jobNameFilters.toLowerCase());
  };

  updateWorkflowFilters = (filters) =>
    this.setState({ workflowsFilters: filters });

  filterWorkflows = (DAG) => {
    if (this.state.workflowsFilters.length) {
      const dagLists = this.state.dags_per_workflow[DAG.id];
      if (!dagLists) return false;
      if (
        dagLists.filter((target) =>
          this.state.workflowsFilters.includes(target)
        ).length
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  hasWorkflowUpdated = () => {
    const originalWorkflow = [...this.state.current_workflows_chain].map(
      (workflow) => ({
        dag: workflow.dag,
        dependencies: workflow.dependencies,
        position: {
          x: workflow?.position?.x ? parseFloat(workflow.position.x) : 0,
          y: workflow?.position?.y ? parseFloat(workflow.position.y) : 0,
        },
      })
    );
    const newWorkflow = [...this.state.new_workflows_chain];

    if (newWorkflow.length === 0) {
      return false;
    }

    if (_.isEqual(newWorkflow, originalWorkflow)) {
      return false;
    } else {
      return true;
    }
  };

  handleTabClick = (type) => {
    if (
      this.hasWorkflowUpdated() &&
      this.state.isWorkflowBuilderShown &&
      this.state.workflowBuilderType === "visualize"
    ) {
      this.submitVisualization();
    } else if (this.hasWorkflowUpdated() && this.state.isWorkflowBuilderShown) {
      this.showLoseWorkflowProgressModal();
    } else {
      this.setState({
        isWorkflowBuilderShown: false,
        active_dag_id_list: [],
        active_dag_data: {},
        active_dag_loading: false,
        active_dag_error_msg: null,
        active_dag_child_process_id_list: [],
        isShowingDagHistoryTable: false,
      });
      this.props.router.navigate("/jobs/overview");
      this.updateActiveTab(type);
    }
  };

  handleCloseWorkflow = () => {
    if (this.hasWorkflowUpdated() && this.state.isWorkflowBuilderShown) {
      this.showLoseWorkflowProgressModal();
    } else {
      this.setState({
        isWorkflowBuilderShown: false,
        modal_lose_workflow_progress: false,
        new_workflows_chain: [],
        current_workflows_chain: [],
        modal_edit_workflow: false,
        modal_workflow: false,
      });
      this.props.router.navigate("/jobs/overview");
    }
  };

  showLoseWorkflowProgressModal = () => {
    this.setState({ modal_lose_workflow_progress: true });
    document.activeElement.blur();
  };

  updateActiveTab = (type) => {
    switch (type) {
      case "Workflows":
        this.setState({
          activeTab: "Workflows",
          dag_id: false,
          workflowsFilters: [],
          jobNameFilters: [],
        });
        break;
      case "Jobs":
        this.setState({
          activeTab: "Jobs",
          dag_id: false,
          workflowsFilters: [],
          jobNameFilters: [],
        });
        break;

      default:
        this.setState({ workflowsFilters: [], jobNameFilters: [] });
        break;
    }
  };

  renderWorkFlowPlayButton = (workflow) => {
    // FIXME:
    const user = jwtDecode(localStorage.getItem("accessToken"));
    if (["max@datajolt.i", "rob@datajolt.i"].includes(user?.email)) {
      const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
          href=""
          ref={ref}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClick(e);
          }}
          style={{
            width: "26px",
            height: "26px",
            borderRadius: "50%",
            backgroundColor: "rgb(243, 243, 243)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {children}
        </div>
      ));

      return (
        <Dropdown
          style={{ width: "26px", display: "inline-block" }}
        >
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            {workflow.state == "running" || workflow.state == "queued" ? (
              <CircularProgress size={16} sx={{ color: "#2c49fc" }} />
            ) : (
              <PlayIcon />
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={
                workflow.state == "running" ||
                workflow.state == "queued" ||
                workflow.state == "Scheduling..."
              }
              onClick={async (e) => {
                e.stopPropagation();
                await rerunWorkflowService(workflow.workflowname);
              }}
            >
              Rerun DAG from failure
            </Dropdown.Item>
            <Dropdown.Item
              disabled={
                workflow.state == "running" ||
                workflow.state == "queued" ||
                workflow.state == "Scheduling..."
              }
              onClick={(e) => {
                e.stopPropagation();
                this.triggerWorkflow(workflow.workflowname);
              }}
            >
              Trigger DAG
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      if (!workflow) return;
      return (
        <MenuItem sx={{ padding: 0 }}>
          <Tooltip title="Trigger DAG" placement="bottom">
            <Button2
              size="sm"
              variant="no-outline-success"
              disabled={
                workflow.state == "running" ||
                workflow.state == "queued" ||
                workflow.state == "Scheduling..."
              }
              onClick={(e) => {
                e.stopPropagation();
                this.triggerWorkflow(workflow.workflowname);
              }}
              sx={{
                minWidth: 0,
                padding: 0,
                borderRadius: "50%",
                width: "26px",
                height: "26px",
                backgroundColor: "#f3f3f3",
                transition: "all ease",

                "&:disabled": {
                  cursor: "not-allowed !important",
                  opacity: 0.2,
                },

                "&:hover": {
                  backgroundColor: "#2c49fc",
                  color: "#f3f3f3",

                  "& svg, & svg *": {
                    fill: "#f3f3f3",
                  },
                },

                [`& svg:not(.${circularProgressClasses.svg})`]: {
                  width: "12px",
                  height: "12px",
                  fill: "#2c49fc",
                  transition: "all ease",

                  "&.bigger-svg": {
                    width: "17px",
                    height: "17px",
                  },
                },
              }}
            >
              {workflow.state == "running" ||
              workflow.state == "queued" ||
              workflow.state == "Scheduling..." ? (
                <CircularProgress size={16} sx={{ color: "#2c49fc" }} />
              ) : (
                <PlayIcon />
              )}
            </Button2>
          </Tooltip>
        </MenuItem>
      );
    }
  };

  toggleEditWorkflow = (workflow_name = "") => {
    this.setState({
      isWorkflowBuilderShown: true,
      workflowBuilderType: "edit",
      workflow_name: workflow_name,
      modal_edit_workflow: !this.state.modal_workflow,
      current_workflows_chain:
        this.state?.workflows?.find(
          (workflow) => workflow.workflowname === workflow_name
        )?.workflow_chain || [],
    });
  };

  toggleCreateWorkflow = () => {
    this.setState({
      modal_workflow: true,
      new_workflows_chain: [],
      isWorkflowBuilderShown: true,
      workflowBuilderType: "create",
      isShowingDagHistoryTable: false,
    });
    this.props.router.navigate("/jobs/overview");
  };

  handleNewWorkflowClose = () => {
    if (this.state.new_workflows_chain.length) {
      toast.error("you didn't save your DAG");
      this.setState({ modal_lose_workflow_progress: true });
    } else {
      this.setState({ new_workflows_chain: [], modal_workflow: false });
    }
  };

  handleEditWorkflowClose = () => {
    if (this.state.new_workflows_chain.length) {
      toast.error("you didn't save your DAG");
      this.setState({ modal_lose_workflow_progress: true });
    } else {
      this.setState({
        new_workflows_chain: [],
        current_workflows_chain: [],
        modal_edit_workflow: false,
        new_workflows_chain: [],
        modal_workflow: false,
      });
    }
  };

  submitVisualization = async () => {
    if (this.state.isVisualizingDBT) {
      //WIP
      // axios
      //   .post(api_url + "/api/workflows/get_DBT_model", {
      //     workflow_name: visualize_name,
      //     workflowChain: this.state.new_workflows_chain,
      //   })
      //   .then((response) => {
      //     const DBT_workflow_chain = response.data.workflow_chain;
      //     this.setState({
      //       isWorkflowBuilderShown: true,
      //       workflowBuilderType: "visualize",
      //       visulaizeHeaderText,
      //       current_workflows_chain: DBT_workflow_chain,
      //     });
      //   })
      //   .catch((err) => {
      //     toast.error("Error visualising your DBT model");
      //   })
      //   .finally(() => {
      //     this.setState({ creating_viz: false });
      //   });

      console.log("submitting DBT MODEL");
    } else {
      await this.editWorkflow(
        this.state.workflow_name,
        false,
        "Saving your nodes position...'"
      );
      this.props.router.navigate("/jobs/overview");
      this.fetchDAGs();
    }
  };

  visualiseFlow = (
    task_level,
    visualize_name,
    last_run_started,
    state,
    dag_id
  ) => {
    if (task_level == 1) {
      //TBD the new DBT model status api
      // axios
      //   .get(api_url + "/api/workflows/get_DBT_model/name")
      //   .then((response) => {
      //     const DBT_workflow_chain = response.data.workflow_chain;
      //     this.setState({
      //       isWorkflowBuilderShown: true,
      //       workflowBuilderType: "visualize",
      //       visulaizeHeaderText,
      //       current_workflows_chain: DBT_workflow_chain,
      //     });
      //   })
      //   .catch((err) => {
      //     toast.error("Error visualising your DBT model");
      //   })
      //   .finally(() => {
      //     this.setState({ creating_viz: false });
      //   });
      //   this.setState({
      //     isWorkflowBuilderShown: true,
      //     workflowBuilderType: "visualize",
      //     visulaizeHeaderText,
      //     current_workflows_chain: [],
      //     workflow_name: visualize_name,
      //     isVisualizingDBT: task_level,
      //   });
    } else {
      this.setState({
        isWorkflowBuilderShown: true,
        workflowBuilderType: "visualize",
        isVisualizingDBT: parseInt(task_level) || 0,
        workflow_name: visualize_name,
        selected_dag_id: dag_id,
      });
    }
  };

  createWorkflow = async (workflow_name) => {
    const toastId = toast.loading("Creating your DAG...");
    if (!workflow_name) {
      toast.error("Please name your DAG.", { autoClose: 5000 });
    } else if (/[`!@#$£%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/.test(workflow_name)) {
      toast.error('DAG cannot have any special characters except "_".', {
        autoClose: 5000,
      });
    } else if (
      this.state.workflows.map((obj) => obj.id).includes(workflow_name)
    ) {
      toast.error("A DAG called " + workflow_name + " already exists", {
        autoClose: 5000,
      });
    } else if (this.state.new_workflows_chain.length < 2) {
      toast.error("Chain at least two tasks to create a DAG.", {
        autoClose: 5000,
      });
    } else if (this.checkCircularReference(this.state.new_workflows_chain)) {
      toast.dismiss(toastId);
      toast.error("Please fix the circular reference in your DAG", {
        autoClose: 5000,
      });
    } else if (
      this.state.workflows
        .map((obj) => obj.workflowname)
        .includes(workflow_name)
    ) {
      toast.error(
        "There is already a DAG called " +
          workflow_name +
          ". Please choose another name.",
        { autoClose: 5000 }
      );
    } else {
      this.setState({ creating_workflow: true });

      const isSuccess = await createWorkflowService(
        toastId,
        workflow_chain,
        workflow_name
      );

      if (isSuccess) {
        this.setState({
          modal_workflow: false,
          modal_edit_workflow: false,
          new_workflows_chain: [],
          workflow_name: "",
          current_workflows_chain: [],
          isWorkflowBuilderShown: false,
          progress: 100,
        });
        this.fetchDAGs();
      }

      this.setState({ progress: 100, creating_workflow: false });
      this.props.router.navigate("/jobs/overview");
    }
  };

  hasCycle = (node, visited, stack, graph) => {
    visited[node] = true;
    stack[node] = true;

    const neighbors = graph[node] || [];
    for (let neighbor of neighbors) {
      if (!visited[neighbor]) {
        if (this.hasCycle(neighbor, visited, stack, graph)) {
          return true;
        }
      } else if (stack[neighbor]) {
        return true;
      }
    }

    stack[node] = false;
    return false;
  };

  checkCircularReference = (data) => {
    const graph = {};
    data.forEach((item) => {
      graph[item.dag] = item.dependencies;
    });

    const visited = {};
    const stack = {};

    for (let node in graph) {
      if (!visited[node]) {
        if (this.hasCycle(node, visited, stack, graph)) {
          return true;
        }
      }
    }
    return false;
  };

  editWorkflow = async (
    workflow_name,
    shouldRefetchDAGs = true,
    toastText = "Editing your DAG..."
  ) => {
    this.setState({ creating_workflow: true });
    if (this.state.new_workflows_chain.length < 2) {
      toast.error("Chain at least two tasks to create a DAG.", {
        autoClose: 5000,
      });
      this.setState({ creating_workflow: false });
    } else if (this.checkCircularReference(this.state.new_workflows_chain)) {
      toast.error("Please remove the circular reference in your DAG", {
        autoClose: 5000,
      });
      this.setState({ creating_workflow: false });
    } else {
      const isSuccess = await editWorkflowService(
        toastText,
        this.state.new_workflows_chain,
        workflow_name
      );

      if (isSuccess) {
        this.setState({
          modal_workflow: false,
          modal_edit_workflow: false,
          new_workflows_chain: [],
          workflow_name: "",
          current_workflows_chain: [],
          isWorkflowBuilderShown: false,
          progress: 100,
        });
        if (shouldRefetchDAGs) {
          this.fetchDAGs();
        }
      }

      this.setState({ progress: 100, creating_workflow: false });
      this.props.router.navigate("/jobs/overview");
    }
  };

  updateWorkflowsData = (updatedWorkflows) =>
    this.setState({ new_workflows_chain: updatedWorkflows });

  compareTimestamps = (a, b) => {
    const { type, sorterSource } = this.state.dagsSorting;
    const timestampA = a[sorterSource];
    const timestampB = b[sorterSource];

    if (type === "ascending") {
      return new Date(timestampA) - new Date(timestampB);
    } else if (type === "descending") {
      return new Date(timestampB) - new Date(timestampA);
    }
  };

  sortedDagsList = () => {
    if (this.state.dagsSorting && this.state.DAGs && this.state.dag_runs) {
      const dagsList = [...this.state.DAGs].map((DAG) => ({
        ...DAG,
        start_date:
          this.state.dag_runs[DAG.id]?.[0]?.start_date || '"2000-07-02 08:00',
        end_date:
          this.state.dag_runs[DAG.id]?.[0]?.end_date || '"2000-07-02 08:00',
      }));

      return dagsList.slice().sort(this.compareTimestamps);
    } else {
      return this.state.DAGs;
    }
  };

  updateSortingOptions = (sorterSource, sortingType) => {
    const sortingDetails = this.state.dagsSorting;
    if (!sortingDetails) {
      // initial sort no active sort
      this.setState({ dagsSorting: { type: sortingType, sorterSource } });
    } else {
      if (
        sortingDetails.type === sortingType &&
        sortingDetails.sorterSource === sorterSource
      ) {
        this.setState({ dagsSorting: null });
      } else {
        this.setState({ dagsSorting: { type: sortingType, sorterSource } });
      }
    }
  };

  renderSortIndicator = (sorterSource) => {
    const sortingDetails = this.state.dagsSorting;

    return (
      <div>
        <Icon.ArrowUp
          size={14}
          onClick={() => this.updateSortingOptions(sorterSource, "ascending")}
          color={
            sortingDetails?.sorterSource === sorterSource &&
            sortingDetails?.type === "ascending"
              ? "blue"
              : "#879fb5"
          }
        />

        <Icon.ArrowDown
          size={14}
          onClick={() => this.updateSortingOptions(sorterSource, "descending")}
          color={
            sortingDetails?.sorterSource === sorterSource &&
            sortingDetails?.type === "descending"
              ? "blue"
              : "#879fb5"
          }
        />
      </div>
    );
  };

  renderDAGStatus = (id) => {
    const statusHistory = this.state?.dag_runs?.[id];
    const lastStatus = statusHistory?.[0]?.state;
    return statusHistory ? (
      lastStatus == "failed" ? (
        <Button
          variant="live-danger"
          className="me-1 mb-1 transparent-bg"
          size="sm"
        >
          &nbsp;Failed
        </Button>
      ) : lastStatus == "success" ? (
        <Button
          className="me-1 mb-1 transparent-bg"
          variant="live-success"
          size="sm"
        >
          Success
        </Button>
      ) : lastStatus == "queued" ? (
        <Button
          size="sm"
          variant="live-secondary"
          className="me-1 mb-1 transparent-bg"
        >
          Queued
        </Button>
      ) : lastStatus == "running" ? (
        <Button
          size="sm"
          variant="live-success"
          className="me-1 mb-1 transparent-bg"
        >
          Running...
        </Button>
      ) : (
        lastStatus.state
      )
    ) : this.state.DAGs.find((target) => target.id === id) &&
      this.state.DAGs.find((target) => target.id === id)?.state ? (
      <Button
        className="me-1 mb-1 transparent-bg"
        type="submit"
        variant="live-success"
        size="sm"
      >
        Scheduling...
      </Button>
    ) : (
      <Button
        className="me-1 mb-1 transparent-bg"
        type="submit"
        variant="live-secondary"
        size="sm"
      >
        Fetching...
      </Button>
    );
  };

  calculateTotalPages = () => {
    const DAGSlist = this.state.workflows;
    const currentActiveTable = this.state.activeTab;

    const totalLength =
      currentActiveTable === "workflows"
        ? this.state.workflows_count
        : DAGSlist.filter((DAG) => {
            if (currentActiveTable === "Jobs") {
              return true;
            } else {
              return true;
              // FIXME: this will return error, as workflows doesn't have type, and there isn't a key called "activeType" in state
              // return DAG.type.toLowerCase() == this.state.activeType;
            }
          })
            .filter((DAG) => this.filterJobNames(DAG))
            .filter((DAG) => this.filterWorkflows(DAG)).length;

    return totalLength ? Math.ceil(totalLength / this.state.perPage) : 1;
  };

  paginateArray = (
    array,
    activePage = this.state.currentPage,
    perPageCount = this.state.perPage
  ) => {
    const startIndex = (activePage - 1) * perPageCount;
    const endIndex = startIndex + perPageCount;

    return array.slice(startIndex, endIndex);
  };

  onSortColumn = (column) => {
    const workflows = this.state.workflows.map((workflow) => ({ ...workflow }));
    let isAsc = true;

    if (this.state.sortedColumn.column === column) {
      isAsc = !this.state.sortedColumn.isAsc;
    }

    this.setState({
      workflows: workflows.sort((a, b) => {
        if (column === "duration") {
          // this condetion can be enhaned to check if both entitis are numbers and we can provide a proper condetion for such casses
          if (isAsc) {
            return Number(a[column]) < Number(b[column]) ? 1 : -1;
          } else {
            return Number(b[column]) < Number(a[column]) ? 1 : -1;
          }
        }
        if (isAsc) {
          return a[column]?.toLowerCase() < b[column]?.toLowerCase() ? 1 : -1;
        } else {
          return b[column]?.toLowerCase() < a[column]?.toLowerCase() ? 1 : -1;
        }
      }),
    });
    this.setState({ sortedColumn: { column, isAsc } });
  };

  render() {
    const { navigate } = this.props.router;
    const isViewingVisualizationLink = new URLSearchParams(
      window.location.search
    ).get("workflow");
    return (
      <div
        id={`overview${
          this.state.isWorkflowBuilderShown
            ? "-" + this.state.workflowBuilderType + "-workflow"
            : "-viewer"
        }`}
        className="container-fluid py-3"
      >
        <LoadingBar color="#0245a0" progress={this.state.progress} />
        <Helmet title="Datajolt | Scheduled Jobs" />

        {!this.state.isWorkflowBuilderShown && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "12px 8px",
              height: "45px",
            }}
          >
            <h4 style={{ margin: 0, width: "260px" }}>
              <b style={{ color: "#090E47" }}>
              My Scheduled DAGs {!this.state.loading_workflows && `(${this.state.workflows_count})`}              </b>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Header as="h3">{"What are DAGs ?"}</Popover.Header>
                    <Popover.Body>
                      DAGs are jobs running on a specific cadence. They are
                      orchestrating your data flows.
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button variant="no-outline-primary">
                  <DetailsIcon />
                </Button>
              </OverlayTrigger>{" "}
            </h4>
            {/* <Button
              variant="generaltabs"
              style={{ marginLeft: "10px" }}
              onClick={() => this.handleTabClick("Workflows")}
              className={
                this.state.activeTab === "Workflows"
                  ? "active wf-tabs"
                  : "inactive wf-tabs"
              }
            >
              DAGs{" "}
              {this.state.loading_workflows
                ? ""
                : "(" + this.state.workflows_count + ")"}
            </Button> */}
            {/* <Button
              variant="generaltabs"
              style={{ marginLeft: "10px" }}
              onClick={() => this.handleTabClick("Taps")}
              className={
                this.state.activeTab === "Taps"
                  ? "active wf-tabs"
                  : "inactive wf-tabs"
              }
            >
              Triggers {this.state.loading ? "" : "(" + this.state.taps_count + ")"}
            </Button>
            <Button
              variant="generaltabs"
              style={{ marginLeft: "10px" }}
              onClick={() => this.handleTabClick("Models")}
              className={
                this.state.activeTab === "Models"
                  ? "active wf-tabs"
                  : "inactive wf-tabs"
              }
            >
              Models{" "}
              {this.state.loading ? "" : "(" + this.state.models_count + ")"}
            </Button> */}
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Tooltip title="Refresh Jobs" placement="bottom">
                <div
                  className="me-1 mb-1 mr-2"
                  disabled={this.state.loading}
                  onClick={() => this.fetchDAGs()}
                >
                  {" "}
                  {this.state.loading ? (
                    <CircularProgress size={16} sx={{ color: "#2c49fc" }} />
                  ) : (
                    <RefreshIcon />
                  )}
                </div>
              </Tooltip>
              {/* <Button
                variant="generaltabs"
                className="active lighter-hover"
                disabled={false}
                onClick={() => this.toggleCreateWorkflow()}
                style={{
                  padding: "5px 7px",
                  borderRadius: "5px",
                  background: "#2D49FD",
                }}
              >
                Create a DAG{" "}
                <span style={{ marginLeft: "5px" }}>&#128640; </span>
              </Button> */}
            </div>
          </div>
        )}

        {!this.state.isWorkflowBuilderShown && !isViewingVisualizationLink && (
          <>
            <hr
              style={{
                margin: "0 8px",
              }}
            />
            <Col md="12">
              {!this.state.loading &&
              !this.state.loading_workflows &&
              this.state.workflows.length == 0 ? (
                <>
                  <br />
                  <br />
                  <center>
                    <h4>
                      You currently have no{" "}
                      {this.state.activeTab == "Workflows"
                        ? "DAGs"
                        : this.state.activeTab}{" "}
                      scheduled.
                    </h4>
                    <br />
                  </center>
                  <br />
                </>
              ) : (
                <>
                  {this.state.activeTab == "Workflows" && (
                    <Table
                      headings={[
                        {
                          Children: ({ children }) => (
                            <span className="d-flex align-items-center h-100">
                              DAG name{" "}&nbsp;
                              <SearchJobs
                                onFilterChange={this.updateJobNameFilters}
                                currentFilter={this.state.jobNameFilters}
                              />
                              {children}
                            </span>
                          ),
                          isSortable: true,
                          columnName: "workflowname",
                        },
                        {
                          Children: ({ children }) => (
                            <span className="d-flex align-items-center h-100">
                              Frequency
                              {children}
                            </span>
                          ),
                          columnName: "frequency",
                          isSortable: true,
                        },
                        {
                          Children: ({ children }) => (
                            <span className="d-flex align-items-center h-100">
                              Last run started
                              {children}
                            </span>
                          ),
                          columnName: "started_at",
                          isSortable: true,
                        },
                        {
                          Children: ({ children }) => (
                            <span className="d-flex align-items-center h-100">
                              Last run ended
                              {children}
                            </span>
                          ),
                          columnName: "ended_at",
                          isSortable: true,
                        },
                        {
                          Children: ({ children }) => (
                            <span className="d-flex align-items-center h-100">
                              Duration
                              {children}
                            </span>
                          ),
                          columnName: "duration",
                          isSortable: true,
                        },
                        {
                          Children: () => "Status",
                          columnName: "status",
                          isSortable: false,
                        },
                        {
                          Children: ({ children }) => (
                            <span className="d-flex align-items-center h-100">
                              Next run
                              {children}
                            </span>
                          ),
                          columnName: "next_run",
                          isSortable: true,
                        },
                        {
                          Children: ({ children }) => (
                            <span className="d-flex align-items-center h-100">
                              Owner
                              {children}
                            </span>
                          ),
                          columnName: "created_by",
                          isSortable: true,
                        },
                        {
                          width: "6%",
                          Children: () => "Actions",
                          columnName: "actions",
                          isSortable: false,
                        },
                      ]}
                      rows={this.paginateArray(
                        this.state.workflows
                          .filter(this.filterJobNames)
                          .filter((DAG) => this.filterWorkflows(DAG)) || []
                      ).map((workflow) => ({
                        key: workflow.workflowname,
                        style: {
                          cursor: this.state.workflows_metadata
                            ? "pointer"
                            : "unset",
                        },
                        onClick: () => {
                          if (this.state.workflows && workflow.allow_edits) {
                            this.visualiseFlow(
                              0,
                              workflow.workflowname,
                              workflow.started_at,
                              workflow.state,
                              workflow.dag_id
                            );
                            navigate(
                              `/jobs/overview?workflow=${workflow.workflowname}&workflow_type=0`
                            );
                          }
                          else {
                            toast.info("This is a custom DAG. Visualization is not available.");
                          }
                        },
                        columns: [
                          {
                            type: "text",
                            text: workflow.workflowname,
                          },
                          {
                            type: "text",
                            text: workflow.frequency,
                          },
                          {
                            type: "date",
                            date: workflow.started_at,
                          },
                          {
                            type: "date",
                            date: workflow.ended_at,
                          },
                          {
                            type: "text",
                            text: workflow.duration
                              ? Math.round(workflow.duration) + " min"
                              : "",
                          },
                          {
                            type: "status",
                            key: workflow.state,
                          },
                          {
                            type: "text",
                            text: workflow.paused ? (
                              <PausedIcon className="centered-svg-block" />
                            ) : workflow.next_run ? (
                              moment
                                .utc(workflow.next_run)
                                .tz(customer_timezone)
                                ?.format("YYYY-MM-DD HH:mm")
                            ) : (
                              ""
                            ),
                          },
                          {
                            type: "text",
                            text: workflow.created_by,
                          },
                          {
                            type: "component",
                            component: (
                              <HorizontalDropdownMenu
                                items={[
                                  {
                                    tooltipTitle: !workflow.paused ? "Pause" : "Unpause",
                                    variant: workflow.paused
                                      ? "secondary"
                                      : "no-outline",
                                    className: "me-1 mb-1",
                                    disabled: this.state.pausing,
                                    onClick: (e) => {
                                      e.stopPropagation();
                                      this.togglePause(
                                        workflow.workflowname,
                                        workflow.dag_id,
                                        workflow.paused
                                      );
                                    },
                                    children: <PausingIcon />,
                                  },
                                  {
                                    tooltipTitle: "History",
                                    variant: "no-outline",
                                    className: "me-1 mb-1",
                                    onClick: (e) => {
                                      e.stopPropagation();
                                      this.redirectToHistoryModalView(workflow);
                                    },
                                    children: <OptionsIcon />,
                                  },
                                  {
                                    tooltipTitle: "Edit",
                                    variant: "no-outline",
                                    className: "mb-1",
                                    disabled:
                                      !this.state.workflows ||
                                      !workflow.allow_edits,
                                    onClick: (e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        isShowingDagEdit: true,
                                        selected_edit_dag:
                                          workflow.workflowname,
                                        advanced_settings:
                                          workflow.advanced_settings,
                                      });
                                      navigate(
                                        `/jobs/overview?dag_edit=${workflow.workflowname}`
                                      );
                                    },
                                    children: (
                                      <SettingsIcon className="bigger-svg" />
                                    ),
                                  },
                                  {
                                    tooltipTitle: "Stop",
                                    variant: "no-outline-danger",
                                    className: "mb-1 me-1",
                                    disabled: false,
                                    onClick: (e) => {
                                      e.stopPropagation();
                                      this.stopDAG(workflow.workflowname);
                                    },
                                    children: <StopIcon />,
                                  },
                                  {
                                    tooltipTitle: "Delete",
                                    variant: "no-outline-danger",
                                    className: "mb-1",
                                    disabled: false,
                                    onClick: (e) => {
                                      e.stopPropagation();
                                      this.toggleDeleteWorkflow(
                                        workflow.workflowname
                                      );
                                    },
                                    children: <CancelIcon />,
                                  },
                                ]}
                              >
                                {this.renderWorkFlowPlayButton(workflow)}
                              </HorizontalDropdownMenu>
                            ),
                          },
                        ],
                      }))}
                      sortedColumn={this.state.sortedColumn}
                      onSortColumn={this.onSortColumn}
                      isLoading={this.isTableLoading()}
                    />
                  )}
                  <TablePagination
                    currentPage={this.state.currentPage}
                    totalPages={this.calculateTotalPages()}
                    updatePageNum={(num) => this.setState({ currentPage: num })}
                    updatePerPage={(num) => this.setState({ perPage: num })}
                    activeTable={`${this.state.activeTab}-${this.state?.jobNameFilters}-${this.state?.workflowsFilters?.length}`}
                  />
                </>
              )}
            </Col>
          </>
        )}

        {isViewingVisualizationLink && !this.state.isWorkflowBuilderShown && (
          <div className="card-body">
            <div
              className="card"
              style={{
                minHeight: "500px",
                height: "689px",
                background: "rgb(243, 243, 243)",
                border: "1px solid #ccc",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <>
                  <center>
                    <CircularProgress size={16} sx={{ color: "#2c49fc" }} />
                  </center>
                  <br />
                </>
              </div>
            </div>
          </div>
        )}

        {this.state.isWorkflowBuilderShown &&
          this.state.workflowBuilderType === "visualize" && (
            <div className="card-body workflow-vis-wrapper">
              <div className="card vis-wrapper">
                <header className="vis-header">
                  <>
                    <small
                      style={{
                        fontWeight: 500,
                        fontSize: 12,
                        color: "#8488A9",
                        border: "2px solid #D0D5DD",
                        borderRadius: "7px",
                        display: "flex",
                        alignItems: "center",
                        padding: "12px 15px",
                        height: "48px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          backgroundColor: "#111137",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "8px",
                        }}
                      >
                        <span style={{ fontSize: "16px", color: "#FFFFFF" }}>
                          🚀
                        </span>
                      </div>
                      <b style={{ marginBottom: 0, color: "#090e47" }}>
                        {this.state.workflow_name}
                      </b>
                    </small>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <small
                        style={{
                          fontWeight: 500,
                          fontSize: 12,
                          color: "#8488A9",
                          border: "2px solid #D0D5DD",
                          padding: "12px 15px",
                          borderRadius: "7px",
                          display: "flex",
                          alignItems: "center",
                          height: "48px",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <TimeIcon />
                        <span style={{ margin: "0 3px" }} />
                        Last run: {"  "}
                        {this.state.workflows?.find(
                          (workflow) =>
                            workflow.workflowname === this.state.workflow_name
                        )?.started_at
                          ? moment
                              .utc(
                                this.state.workflows?.find(
                                  (workflow) =>
                                    workflow.workflowname ===
                                    this.state.workflow_name
                                ).started_at
                              )
                              .tz(customer_timezone)
                              ?.format("YYYY-MM-DD HH:mm")
                          : "Never"}
                        {"   "} <span style={{ margin: "0 10px" }}>||</span>{" "}
                        {"   "}
                        <StateIcon />
                        <span style={{ margin: "0 3px" }} />
                        Last run status: {"  "}
                        <span style={{ margin: "3px" }}>
                          {
                            <TableTooltip
                              status={
                                this.state.workflows?.find(
                                  (workflow) =>
                                    workflow.workflowname ===
                                    this.state.workflow_name
                                )?.state
                              }
                            />
                          }
                        </span>
                      </small>
                    </div>
                    <span style={{ display: "flex", gap: "5px" }}>
                      <button
                        style={{
                          color: "white",
                          border: "1px solid #D0D5DD",
                          borderRadius: "5px",
                          background: "#27cc97",
                          padding: "10px 15px",
                          fontWeight: "500",
                          fontSize: "15px",
                          lineHeight: "25px",
                        }}
                        disabled={
                          this.state.workflows?.find(
                            (workflow) =>
                              workflow.workflowname === this.state.workflow_name
                          )?.state === "running" ||
                          this.state.workflows?.find(
                            (workflow) =>
                              workflow.workflowname === this.state.workflow_name
                          )?.state === "queued"
                        }
                        onClick={() =>
                          this.triggerWorkflow(this.state.workflow_name)
                        }
                      >
                        {this.state.workflows?.find(
                          (workflow) =>
                            workflow.workflowname === this.state.workflow_name
                        )?.state === "running" ||
                        this.state.workflows?.find(
                          (workflow) =>
                            workflow.workflowname === this.state.workflow_name
                        )?.state === "queued" ? (
                          <>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <CircularProgress size={16} sx={{ color: "#fff" }} />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </>
                        ) : (
                          <>
                            <Icon.PlayCircle
                              size={18}
                              style={{
                                marginRight: "5px",
                                marginBottom: "3px",
                              }}
                            />
                            Play
                          </>
                        )}
                      </button>
                      <button
                        style={{
                          color: "white",
                          border: "1px solid #D0D5DD",
                          borderRadius: "5px",
                          background: "#E34234",
                          padding: "10px 15px",
                          fontWeight: "500",
                          fontSize: "15px",
                          lineHeight: "25px",
                        }}
                        disabled={false}
                        onClick={() => this.stopDAG(this.state.workflow_name)}
                      >
                        <Icon.StopCircle
                          size={18}
                          style={{ marginRight: "5px", marginBottom: "3px" }}
                        />
                        Stop
                      </button>
                      {/* <button
                        style={{
                          border: "1px solid #D0D5DD",
                          borderRadius: "5px",
                          background: "white",
                          padding: "10px 15px",
                          fontWeight: "500",
                          fontSize: "15px",
                          lineHeight: "25px",
                        }}
                        disabled={
                          this.state.pausing || !this.state.workflows_metadata
                        }
                        onClick={() =>
                          this.toggleEditWorkflow(this.state.workflow_name)
                        }
                      >
                        <Icon.Edit3
                          size={18}
                          style={{ marginRight: "5px", marginBottom: "3px" }}
                        />
                        Edit
                      </button>*/}
                      <button
                        style={{
                          color: "white",
                          border: "1px solid #D0D5DD",
                          borderRadius: "5px",
                          background: "#abaaaa",
                          padding: "10px 15px",
                          fontWeight: "500",
                          fontSize: "15px",
                          lineHeight: "25px",
                        }}
                        onClick={() => {
                          if (
                            this.hasWorkflowUpdated() &&
                            this.state.isWorkflowBuilderShown
                          ) {
                            this.submitVisualization();
                          } else {
                            this.handleCloseWorkflow();
                          }
                        }}
                      >
                        <Icon.X
                          size={18}
                          style={{ marginRight: "5px", marginBottom: "3px" }}
                        />
                        Close
                      </button>
                    </span>
                  </>
                </header>
                <WorkflowVisualiser
                  editorURL={this.state.dbt_url}
                  updateWorkflows={this.updateWorkflowsData}
                  DAGList={this.state.workflows}
                  setCurrentWorkflow={(chain) =>
                    this.setState({ current_workflows_chain: chain })
                  }
                  editWorkflow={{
                    initial_workflow_chain: this.state.current_workflows_chain,
                    workflow_name: this.state.workflow_name,
                    shouldUpdateWorkflowStatuses:
                      this.state.shouldUpdateWorkflowStatuses,
                    stopUpdaterChecker: () =>
                      this.setState({ shouldUpdateWorkflowStatuses: false }),
                    run_id:
                      this.state?.workflows_metadata &&
                      this.state?.workflows_metadata?.[
                        this.state?.workflow_name
                      ] &&
                      Object.keys(
                        this.state?.workflows_metadata?.[
                          this.state?.workflow_name
                        ]
                      )?.[0],
                  }}
                  listDags={this.fetchDAGs}
                />
              </div>
            </div>
          )}

        <CloseDrawerModal
          isShown={this.state.modal_lose_workflow_progress}
          onHide={() => this.setState({ modal_lose_workflow_progress: false })}
          closeableDarwer="your DAG builder"
        >
          <Button2
            variant="contained"
            onClick={() =>
              this.setState({ modal_lose_workflow_progress: false })
            }
            sx={{
              bgcolor: "#495057",
              gap: 0.5,
              marginY: 0.5,
              paddingY: 0.5,
              paddingX: 1.5,
              fontSize: 14,
              fontFamily: "Poppins",
              textTransform: "none",
            }}
          >
            <Icon.X size={16} /> Cancel
          </Button2>
          <Button2
            color="success"
            variant="contained"
            disabled={
              this.state?.workflows_metadata &&
              this.state?.workflows_metadata?.[this.state?.workflow_name] &&
              this.state?.workflows_metadata[this.state.workflow_name][
                Object.keys(
                  this.state?.workflows_metadata[this.state.workflow_name]
                )[0]
              ]["state"] === "running" &&
              this.state.workflowBuilderType !== "create"
            }
            onClick={() => {
              //submit
              this.setState({ modal_lose_workflow_progress: false });
              if (this.state.workflowBuilderType === "visualize") {
                this.submitVisualization();
              } else {
                document.getElementById("submitWorkflowData").click();
              }
            }}
            sx={{
              gap: 0.5,
              marginY: 0.5,
              paddingY: 0.5,
              paddingX: 1.5,
              fontSize: 14,
              fontFamily: "Poppins",
              textTransform: "none",
            }}
          >
            <Icon.Save size={16} /> Save and exit
          </Button2>
          <Button2
            color="error"
            variant="contained"
            onClick={() => {
              // close and lose all
              this.setState({
                isWorkflowBuilderShown: false,
                modal_lose_workflow_progress: false,
                new_workflows_chain: [],
                current_workflows_chain: [],
                modal_edit_workflow: false,
                modal_workflow: false,
              });
              this.props.router.navigate("/jobs/overview");
            }}
            sx={{
              gap: 0.5,
              marginY: 0.5,
              paddingY: 0.5,
              paddingX: 1.5,
              fontSize: 14,
              fontFamily: "Poppins",
              textTransform: "none",
            }}
          >
            <Icon.Trash2 size={16} /> Exit without saving
          </Button2>
        </CloseDrawerModal>

        <DeleteConnectorModal
          isShown={this.state.modal_delete_workflow}
          onHide={() => this.setState({ modal_delete_workflow: false })}
          onAction={() => this.deleteWorkflow()}
          deleteData={{
            deleting: this.state.deleting,
          }}
          text={{
            bold: this.state.selected_dag,
            regular: "DAG",
            regularFirst: true,
          }}
        />

        <CSSTransition
          in={this.state.isShowingDagHistoryTable}
          classNames="form-tab"
          timeout={300}
          unmountOnExit
        >
          <DagHistory
            dagList={
              Boolean(this.state.workflows.length) && this.state.workflows
            }
            show_logs={true}
            onClose={() => {
              this.setState({
                isShowingDagHistoryTable: !this.state.isShowingDagHistoryTable,
              });
              navigate("/jobs/overview");
            }}
          />
        </CSSTransition>

        <CSSTransition
          in={this.state.isShowingDagEdit}
          classNames="form-tab"
          timeout={300}
          unmountOnExit
        >
          <DagEdit
            key={
              this.state.advanced_settings
                ? this.state.advanced_settings.someUniqueIdentifier
                : "initial"
            }
            editing_dag_alias={this.state.selected_edit_dag}
            advanced_settings={this.state.advanced_settings}
            onClose={() => {
              this.setState({ isShowingDagEdit: false });
              navigate("/jobs/overview");
            }}
            fetchDAGs={this.fetchDAGs}
            workflows={this.state.workflows}
            editorURL={this.state.dbt_url}
          />
        </CSSTransition>
      </div>
    );
  }
}

export default WithRouter(TenantContextComponent);
