import React from "react";
import SortBtn from "./SortBtn";
import { TableCell } from "@mui/material";

/**
 * @param {{
  Children: JSX.Element,
  isSortable: boolean,
  columnName: string,
  width?: string,
  sortedColumn: { column: string, isAsc: boolean },
  onSortColumn: (columnName: string) => void,
}} data 
 */
const CustomTableHead = ({
  Children,
  isSortable,
  columnName,
  width,
  sortedColumn,
  onSortColumn,
}) => {
  return (
    <TableCell
      sx={{
        width,
        position: "sticky",
        top: 0,
        zIndex: 99,
        fontSize: "11px",
        lineHeight: "15px",
        wordBreak: "break-all",
        color: "#448593 !important",
        textTransform: "capitalize",
        minWidth: "70px",
        padding: "0rem 0.7rem",
        borderWidth: 0,
        resize: !width ? "horizontal" : "none",
        overflow: "hidden",
        maxWidth: "300px",
      }}
    >
      <Children>
        {isSortable && (
          <SortBtn
            onClick={() => onSortColumn(columnName)}
            isSorted={
              sortedColumn.column === columnName
                ? sortedColumn.isAsc
                  ? "asc"
                  : "desc"
                : "none"
            }
          />
        )}
      </Children>
    </TableCell>
  );
};

export default CustomTableHead;
