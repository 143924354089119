import React, { useState, useEffect, useRef } from "react";
import "./SidebarModal.css";
import { ResizableBox } from "react-resizable";
import * as Icon from "react-feather";
import { Form, Button, Collapse, Accordion } from "react-bootstrap";
import { toast } from "react-toastify";
import LineageVisulaizer from "../../pages/jobs/workflows/lineageVisulaizer";
import { isValidCron } from "cron-validator";
import { createDAGService, fetchWorkflowsService } from "../../services/editor";
import {CircularProgress} from "@mui/material";

const frequenciesOptions = [
  { value: "Hourly", label: "Hourly" },
  { value: "Daily", label: "Daily" },
  { value: "Custom", label: "Custom Cron" },
  { value: "Relative", label: "After another DAG" }
];

const AlertsOptions = [
  { value: "all", label: "When DAG starts and finishes (Success or Failure)" },
  {
    value: "only_finishes",
    label: "Only when DAG finishes (Success or Failure)",
  },
  { value: "only_failures", label: "Only when DAG fails" },
];

export default function SidebarModal({
  setIsShown,
  lineageData,
  testModel,
  testData: {
    running_dbt,
    dbt_logs,
    dbt_test_status,
    running_lineage,
    lineage_status,
  },
  skips_
}) {
  const [skips, setskips] = useState(skips_);
  useEffect(() => {
    setskips(skips_);
  }, [skips_]);

  const [dag_alias, setdag_alias] = useState("");
  const [schedule_dbt_command, setCommand] = useState("");
  const [frequencies, setFrequencies] = useState("");
  const [cronExpression, setCronExpression] = useState(""); // New state for cron expression
  const [retries, setRetries] = useState(3);
  const [dagTimeout, setDagTimeout] = useState(60);
  const [alerts, setAlerts] = useState("");
  const [showTestData, setShowTestData] = useState(false);
  const [isFrequenciesMenuOpen, setFrequenciesMenuOpen] = useState(false);
  const [isAlertsMenuOpen, setAlertsMenuOpen] = useState(false);
  const [creatingDag, setCreatingDag] = useState(false);
  const [isDAGsListOpen, setIsDAGsListOpen] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const alertsButtonRef = useRef(null);
  const alertsMenuRef = useRef(null);
  const handleFrequenciesToggle = (e) => {
    e.stopPropagation();
    setIsDAGsListOpen(false);
    setFrequenciesMenuOpen(!isFrequenciesMenuOpen);
  };
  const selectAlerts = (e, val) => {
    setAlerts(val);
    handleAlertsToggle(e);
  };

  const handleAlertsToggle = (e) => {
    e.stopPropagation();
    setAlertsMenuOpen(!isAlertsMenuOpen);
  };

  const selectFrequencies = (e, val) => {
    setFrequencies(val);
    handleFrequenciesToggle(e);
    setCronExpression("")
  };

  const renderAlertsOptions = () =>
    AlertsOptions.map((alertsOption) => (
      <li
        key={alertsOption.value}
        onClick={(e) => selectAlerts(e, alertsOption.value)}
        className="schedule-menu-item"
      >
        {alertsOption.label}
      </li>
    ));

  const renderFrequenciesOptions = () =>
    frequenciesOptions.map((frequenciesOption) => (
      <li
        key={frequenciesOption.value}
        onClick={(e) => selectFrequencies(e, frequenciesOption.value)}
        className="schedule-menu-item"
      >
        {frequenciesOption.label}
      </li>
    ));

  const createDAG = async () => {
    if (!frequencies) {
      toast.info("Please provide the schedule frequency");
      return;
    } else if (
      frequencies === "Custom" &&
      !isValidCron(cronExpression, { alias: true, seconds: true })
    ) {
      toast.info("Invalid cron expression");
      return;
    } else if (frequencies === "Hourly" && !cronExpression) {
      toast.info(
        "Please input the minute you want your DAG to run at every hour"
      );
      return;
    } else if (frequencies === "Hourly" && cronExpression) {
      const minute = parseInt(cronExpression, 10);
      if (isNaN(minute) || minute < 0 || minute > 59) {
        toast.error("Please input a valid minute (0-59) for hourly DAG run");
        return;
      }
    } else if (frequencies === "Daily" && !cronExpression) {
      toast.info("Please input the hour you want your DAG to run at every day");
      return;
    } else if (frequencies === "Daily" && cronExpression) {
      const hour = parseInt(cronExpression, 10);
      if (isNaN(hour) || hour < 0 || hour > 23) {
        toast.error("Please input a valid hour (0-23) for daily DAG run");
        return;
      }
    }
    else if (frequencies === "Relative" && !cronExpression) {
      toast.info("Please select a DAG in the dropdown");
      return;
    }
    if (!/--select|--s/.test(schedule_dbt_command)) {
      toast.error(
        "Your command doesn't seem to be selecting any node. Make sure you have a --select or --s to select relevant nodes."
      );
      return;
    }
    setCreatingDag(true);
    const isSuccess = await createDAGService({
      dag_alias,
        lineage: lineageData,
        schedule_frequency: frequencies,
        cron_expression: cronExpression,
        dag_timeout: dagTimeout,
        retries,
        dbt_command: schedule_dbt_command,
        alerts,
        skips
    })

    if(isSuccess) {
      setIsShown(false);
    }

    setCreatingDag(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        isAlertsMenuOpen &&
        alertsButtonRef.current &&
        alertsMenuRef.current
      ) {
        const buttonRect = alertsButtonRef.current.getBoundingClientRect();
        alertsMenuRef.current.style.top = `${
          buttonRect.bottom + window.scrollY
        }px`;
        alertsMenuRef.current.style.left = `${
          buttonRect.left + window.scrollX
        }px`;
      }
    };

    window.addEventListener("click", () => {
      setFrequenciesMenuOpen(false);
      setAlertsMenuOpen(false);
    });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAlertsMenuOpen]);

  const fetchWorkflows = async () => {
    const response = await fetchWorkflowsService();

    if(response) {
      setWorkflows(response.data.workflows);
    }
  };

  const selectWorkflow = (DAG) => {
    setCronExpression(DAG);
    setIsDAGsListOpen(false);
  };

  return (
    <ResizableBox
      className={`dragged-box react-resizable sidebar-modal-wrapper`}
      height={window.innerHeight - 50}
      width={window.innerWidth / 3}
      maxConstraints={[window.innerWidth - 65, window.innerHeight - 50]}
      minConstraints={[400, 400]}
      resizeHandles={["w"]}
      id="active-modal-sidebar"
    >
      <section className="schedule-sidebar-content">
        <h2 className="heading-schedule-model">
        <small
                      style={{
                        fontWeight: 500,
                        fontSize: 12,
                        border: "2px solid #D0D5DD",
                        borderRadius: "7px",
                        display: "flex",
                        alignItems: "center",
                        padding: "12px 15px",
                        height: "48px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >            <Icon.ChevronsRight
                    color="#438593"
                    onClick={() => setIsShown(false)}
                    role="button"
                  />{" "}
                        Create DAG
                    </small>
        </h2>
        <Form>
          <Form.Group controlId="modelName" className="mb-3">
            <Form.Label>DAG alias</Form.Label>
            <Form.Control
              value={dag_alias}
              onChange={(e) => setdag_alias(e.target.value)}
              type="text"
              placeholder="Enter DAG alias"
            />
          </Form.Group>

          <Form.Group controlId="dependencies">
            <Form.Label>DBT Command to build DAG from</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                value={schedule_dbt_command}
                onChange={(e) => setCommand(e.target.value)}
                type="text"
                placeholder="Enter DBT Command"
              />
              <Button
                onClick={() => {
                  setShowTestData(true);
                  testModel(schedule_dbt_command);
                }}
                variant="secondary"
                className="ml-2 test-button"
                disabled={showTestData && running_dbt}
              >
                <Icon.Code width={15} className="button-icon" />
                {running_dbt ? "Testing..." : "Test"}
              </Button>
            </div>
          </Form.Group>
          <br />
          <Accordion defaultActiveKey="0" className="pl-0 mb-3">
            <Accordion.Item eventKey="1" className="bg-white">
              <Accordion.Header>
                DBT Test Logs &nbsp;&nbsp;&nbsp;{" "}
                {dbt_test_status === "success" ? (
                  <Icon.Check color="green" />
                ) : dbt_test_status === "failed" ? (
                  <Icon.X color="red" />
                ) : (
                  ""
                )}
                {running_dbt ? (
                  <CircularProgress size={16} color="#fff" />
                ) : (
                  ""
                )}{" "}
              </Accordion.Header>
              {dbt_logs && (
                <Accordion.Body className="p-0 m-2">
                  <div
                    className="log-display"
                    dangerouslySetInnerHTML={{
                      __html: dbt_logs,
                    }}
                  />
                </Accordion.Body>
              )}
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0" className="pl-0 mb-3">
            <Accordion.Item eventKey="1" className="bg-white">
              <Accordion.Header>
                DAG Visualisation &nbsp;&nbsp;&nbsp;{" "}
                {lineage_status === "success" ? (
                  <Icon.Check color="green" />
                ) : lineage_status === "failed" ? (
                  <Icon.X color="red" />
                ) : (
                  ""
                )}
                {running_lineage ? (
                  <CircularProgress size={16} color="#fff" />
                ) : (
                  ""
                )}{" "}
              </Accordion.Header>
              {lineageData.initial_workflow_chain && (
                <Accordion.Body className="p-0 m-2">
                  <LineageVisulaizer
                    editWorkflow={lineageData}
                    style={{ height: "50vh" }}
                  />
                </Accordion.Body>
              )}
            </Accordion.Item>
          </Accordion>
        </Form>

        <Accordion defaultActiveKey="1" className="pl-0 mb-3">
          <Accordion.Item eventKey="1" className="bg-white">
            <Accordion.Header>
              <Icon.Settings size={12} />
              &nbsp;Advanced Settings
            </Accordion.Header>
            <Accordion.Body className="p-0 m-2">
              <Form.Group controlId="schedule_freq">
                <Form.Label>Schedule Frequency</Form.Label>
                <div
                  style={{ position: "relative", display: "flex", gap: "10px" }}
                >
                  <div style={{ flex: 1, position: "relative" }}>
                    <Button
                      onClick={handleFrequenciesToggle}
                      aria-controls="schedule-collapse-frequencies"
                      aria-expanded={isFrequenciesMenuOpen}
                      className={`schedule-val collapse-schedule ${
                        frequencies && "collapse-schedule-chosen"
                      }`}
                    >
                      {frequencies
                        ? frequenciesOptions.find(
                            (opt) => opt.value === frequencies
                          ).label
                        : "Select schedule"}
                    </Button>
                    <Collapse in={isFrequenciesMenuOpen}>
                      <ul
                        id="schedule-collapse-frequencies"
                        className="schedule-menu"
                      >
                        {renderFrequenciesOptions()}
                      </ul>
                    </Collapse>
                  </div>
                  {frequencies === "Relative" ? (
                    <div style={{ flex: 1, position: "relative", maxWidth: "calc(50% - 5px)" }}>
                      <Button
                        onClick={() => {
                          if (!workflows.length) {
                            fetchWorkflows();
                          }
                          setFrequenciesMenuOpen(false);
                          setIsDAGsListOpen((value) => !value);
                        }}
                        aria-controls="schedule-collapse-dags"
                        aria-expanded={isDAGsListOpen}
                        className={`collapse-schedule ${
                          cronExpression && "collapse-schedule-chosen"
                        }`}
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {cronExpression
                          ? workflows.find(
                              (workflow) => workflow.dag_id === cronExpression
                            ).workflowname
                          : "Select DAG"}
                      </Button>
                      <Collapse in={isDAGsListOpen}>
                        <ul id="schedule-collapse-dags" className="schedule-menu">
                          {workflows.length ? (
                            workflows.map((workflow) => (
                              <li
                                key={workflow.dag_id}
                                onClick={() => selectWorkflow(workflow.dag_id)}
                                className="schedule-menu-item"
                              >
                                {workflow.workflowname}
                              </li>
                            ))
                          ) : (
                            <CircularProgress size={16} color="#fff" />
                          )}
                        </ul>
                      </Collapse>
                    </div>
                  ) : (
                    <Form.Control
                      type="text"
                      value={cronExpression}
                      onChange={(e) => setCronExpression(e.target.value)}
                      placeholder={
                        frequencies === "Custom"
                          ? "Enter cron expression"
                          : frequencies === "Hourly"
                          ? "Minute to run at"
                          : "Hour to run at"
                      }
                      className={`collapse-schedule ${
                        frequencies && "collapse-schedule-chosen"
                      }`}
                      style={{ flex: 1 }}
                    />
                  )}
                </div>
              </Form.Group>
              <Form.Group controlId="alerts">
                <Form.Label>Slack Alert</Form.Label>
                <div style={{ position: "relative" }}>
                  <Button
                    ref={alertsButtonRef}
                    onClick={handleAlertsToggle}
                    aria-controls="schedule-collapse-alerts"
                    aria-expanded={isAlertsMenuOpen}
                    className={`schedule-val collapse-schedule ${
                      alerts && "collapse-schedule-chosen"
                    }`}
                  >
                    {alerts
                      ? AlertsOptions.find((opt) => opt.value === alerts).label
                      : ""}
                  </Button>
                  <Collapse in={isAlertsMenuOpen}>
                    <ul
                      id="schedule-collapse-alerts"
                      className="schedule-menu"
                      ref={alertsMenuRef}
                    >
                      {renderAlertsOptions()}
                    </ul>
                  </Collapse>
                </div>
              </Form.Group>
              <Form.Group controlId="dag_timeout">
                <Form.Label>DAG Timeout (in minutes)</Form.Label>
                <Form.Control
                  type="number"
                  value={dagTimeout}
                  onChange={(e) => setDagTimeout(e.target.value)}
                  placeholder="Your DAG will automatically fail after this time (default: 60)"
                />
              </Form.Group>
              <Form.Group controlId="retries">
                <Form.Label>Retries</Form.Label>
                <Form.Control
                  type="number"
                  value={retries}
                  onChange={(e) => setRetries(e.target.value)}
                  placeholder="Retries before considering the DAG failed (default: 3)"
                />
              </Form.Group>
              <Form.Group controlId="sources">
                <Form.Label>Skip Sources (in minutes) - 0 minutes default means never skip</Form.Label>
                {Object.keys(skips).length > 0 ? (
                  Object.keys(skips).map((sourceName) => (
                    <div key={sourceName} className="d-flex mb-2">
                      <Form.Control
                        type="text"
                        value={sourceName}
                        disabled
                        className="mr-2"
                        style={{ width: '60%' }}
                      />
                      <Form.Control
                        type="number"
                        value={skips[sourceName]}
                        onChange={(e) => {
                          const updatedSkips = { ...skips };
                          updatedSkips[sourceName] = e.target.value === '' ? '' : parseInt(e.target.value, 10);
                          setskips(updatedSkips);
                        }}
                        style={{ width: '40%' }}
                      />
                    </div>
                  ))
                ) : running_dbt || running_lineage ? (<div>Waiting for test and lineage to finish to identify sources...</div>):
                (
                  <div>No source identified</div>
                )}
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="d-flex justify-content-between gap-2 mt-auto schedule-model-btns-wrapper">
          <Button
            className="w-100 sc-btn"
            disabled={dbt_test_status !== "success" || creatingDag}
            onClick={createDAG}
          >
            Create my DAG &nbsp;
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" className="button-icon">
              <path d="M505.12019,19.09375c-1.18945-5.53125-6.65819-11-12.207-12.1875C460.716,0,435.507,0,410.40747,0,307.17523,0,245.26909,55.20312,199.05238,128H94.83772c-16.34763.01562-35.55658,11.875-42.88664,26.48438L2.51562,253.29688A28.4,28.4,0,0,0,0,264a24.00867,24.00867,0,0,0,24.00582,24H127.81618l-22.47457,22.46875c-11.36521,11.36133-12.99607,32.25781,0,45.25L156.24582,406.625c11.15623,11.1875,32.15619,13.15625,45.27726,0l22.47457-22.46875V488a24.00867,24.00867,0,0,0,24.00581,24,28.55934,28.55934,0,0,0,10.707-2.51562l98.72834-49.39063c14.62888-7.29687,26.50776-26.5,26.50776-42.85937V312.79688c72.59753-46.3125,128.03493-108.40626,128.03493-211.09376C512.07526,76.5,512.07526,51.29688,505.12019,19.09375ZM384.04033,168A40,40,0,1,1,424.05,128,40.02322,40.02322,0,0,1,384.04033,168Z"></path>
            </svg>
          </Button>
        </div>
      </section>
    </ResizableBox>
  );
}
