import { config } from "../constants";
import axios from "../utils/axios";
import { toast } from "react-toastify";

export const fetchVisualizationsService = async () => {
  try {
    const response = await axios.get(
      `${config.url.AIRFLOW}/api/airflow/list_dags`
    );
    return response;
  } catch (err) {
    toast.error("Error while loading DAGs.");
  }
};

export const stopDagService = async (dag_id, dag_alias) => {
  const toastId = toast.loading(`Stopping ${dag_alias}...`);

  try {
    await axios.post(`${config.url.AIRFLOW}/api/airflow/stop_dag`, {
      dag_id,
    });

    toast.success(`${dag_alias} stopped successfully.`);
  } catch (_) {
    toast.error(`Could not stop your DAG ${dag_alias}.`);
  }
  toast.dismiss(toastId);
};

export const fetchDagsService = async () => {
  try {
    const response = await axios.get(
      `${config.url.AIRFLOW}/api/airflow/list_dags`
    );
    return response;
  } catch (err) {
    toast.error("Error while loading DAGs.");
  }
};

export const deleteWorkflowService = async (dag_alias) => {
  const toastId = toast.loading(`Deleting your DAG ${dag_alias}...`);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.AIRFLOW}/api/airflow/delete_dag`, {
      dag_alias,
    });
    toast.success(`DAG ${dag_alias} deleted successfully`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error("Error while deleting your DAG.");
  }

  toast.dismiss(toastId);
  return isSuccess;
};

export const triggerWorkflowService = async (workflow_name) => {
  const toastId = toast.loading(`Triggering ${workflow_name}...`);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.AIRFLOW}/api/airflow/trigger_dag`, {
      dag_alias: workflow_name,
    });
    toast.success("DAG triggered successfully 🎉");
    isSuccess = true;
  } catch (_) {
    toast.error("Error triggering your DAG.");
  }

  toast.dismiss(toastId);
  return isSuccess;
};

export const togglePauseService = async (workflowname, dag_id, pause) => {
  const toastID = toast.loading(
    `${pause ? "Pausing" : "Unpausing"} ${workflowname}...`
  );
  let isSuccess = false;

  try {
    await axios.post(`${config.url.AIRFLOW}/api/airflow/toggle_pause`, {
      dag_id,
      pause,
    });
    toast.success(`${workflowname} ${!pause ? "un" : ""}paused successfully. 🎉`);
    isSuccess = true;
  } catch (_) {
    toast.error("Error pausing your DAG.");
  }
  toast.dismiss(toastID);

  return isSuccess;
};

export const rerunWorkflowService = async (workflow) => {
  const toastID = toast.loading(`Rerunning ${workflow} from failure...`);

  try {
    await axios.post(
      `${config.url.WORKFLOWS_URL}/api/workflows/trigger_from_failure`,
      {
        workflow,
      }
    );
    toast.success(`${workflow} re-triggered successfully.`);
  } catch (_) {
    toast.error("Error triggering your DAG from failure.");
  }
  toast.dismiss(toastID);
};

export const createWorkflowService = async (toastId, workflow_chain, workflow_name) => {
  let isSuccess = false;

  try {
    await axios.post(
      `${config.url.WORKFLOWS_URL}/api/workflows/create_workflow`,
      {
        workflow_chain,
        workflow_name,
      }
    );
    toast.success(`DAG ${workflow_name} created successfully`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error(`Could not create ${workflow_name}.`, {
      autoClose: 5000,
    });
  }

  toast.dismiss(toastId);
  return isSuccess;
};

export const editWorkflowService = async (
  toastText,
  workflow_chain,
  workflow_name
) => {
  const toastId = toast.loading(toastText);
  let isSuccess = false;

  try {
    await axios.post(`${config.url.WORKFLOWS}/api/workflows/edit_workflow`, {
      workflow_chain,
      workflow_name,
    });
    toast.success(`DAG ${workflow_name} edited successfully`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {}

  toast.dismiss(toastId);
  return isSuccess;
};

export const updateNodeStatusesService = async (run_id, workflow_name) => {
  try {
    const response = await axios.post(
      `${config.url.WORKFLOWS}/api/workflows/metadata/task_level`,
      {
        run_id,
        workflow_name,
      }
    );

    return response;
  } catch (error) {
    toast.error("Error retrieving tasks status");
  }
};

export const handleRefreshingService = async (editorURL, advanced_settings) => {
  try {
    const response = await axios.post(
      `${editorURL}/api/sql/refresh_dag_lineage`,
      {
        advanced_settings,
      }
    );

    if (response.status === 200) {
      toast.success("DAG lineage refreshed successfully.");
      return response.data.lineage;
    } else {
      toast.error("Error refreshing DAG lineage.");
    }
  } catch (_) {
    toast.error("Error refreshing DAG lineage.");
  }
};

export const updateDagService = async (data) => {
  const toastId = toast.loading("Editing your DAG...");
  let isSuccess = false;

  try {
    await axios.post(`${config.url.AIRFLOW}/api/airflow/edit_dag`, data);
    toast.success(`DAG ${data.dag_alias} successfully edited 🎉`, {
      autoClose: 5000,
    });
    isSuccess = true;
  } catch (_) {
    toast.error("There was an issue editing your DAG");
  }
  toast.dismiss(toastId);
  return isSuccess;
};

export const handleRefreshSerivce = async (editorURL, advanced_settings) => {
  try {
    const response = await axios.post(
      `${editorURL}"/api/sql/refresh_dag_lineage`,
      {
        advanced_settings,
      }
    );

    if (response.status === 200) {
      return true;
    } else {
      toast.error("Error refreshing DAG lineage.");
    }
  } catch (_) {
    toast.error("Error refreshing DAG lineage.");
  }
  return false;
};
