import React, { useEffect, useState } from "react";
import { TextField, InputAdornment, Box, Button } from "@mui/material";
import { Search, X } from "react-feather";

const SearchJobs = ({ onFilterChange, groupeType }) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    //clear on changing the active table
    setValue("");
  }, [groupeType]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    onFilterChange(newValue);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClear = () => {
    document.getElementById("search-job-names").focus();
    setValue("");
    onFilterChange("");
  };

  return (
    <>
      {!open && (
        <Box sx={{ position: "relative", width: 16 }}>
          <Button
            id="filter-workflow"
            onClick={handleToggle}
            sx={{
              width: "20px important",
              padding: 0,
              minWidth: "unset",
              display: "inline-block",
            }}
          >
            <Search size={14} color={value ? "blue" : "#879fb5"} />
          </Button>
        </Box>
      )}
      {open && (
        <Box
          sx={{
            // position: "absolute",
            top: "calc(100%)",
            width: "200px",
            zIndex: "88888",
          }}
        >
          <TextField
            id="search-job-names"
            autoFocus
            sx={{
              bgcolor: "white",
              "& > div": {
                padding: 0,
              },
              "& input": {
                padding: 0.3,
                pl: 0.8,
                color: "#6c757d",
              },
              "& fieldset": { top: 0 },
              "& fieldset legend": { display: "none" },
              "& .MuiInputBase-sizeSmall": {
                padding: "0 !important",
              },
            }}
            label={null}
            variant="outlined"
            onBlur={() => {
              setTimeout(() => {
                if (document.activeElement.id !== "search-job-names") {
                  handleToggle();
                }
              }, 300);
            }}
            value={value}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: "pointer" }}
                  id="connectors-search"
                >
                  <Search size={14} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  onClick={handleClear}
                  position="end"
                  sx={{ cursor: "pointer", mr: "8px" }}
                  id="clear-job-search"
                >
                  <X
                    color="#6c757d"
                    size={14}
                    style={{
                      visibility: value ? "visible" : "hidden",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
};

export default SearchJobs;
