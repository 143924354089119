import { Tooltip } from "@mui/material";
import React from "react";
import { ReactComponent as FailedIcon } from "../../../assets/img/icons/failed.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/img/icons/success.svg";
import { ReactComponent as QueuedIcon } from "../../../assets/img/icons/queed.svg";
import runningIcon from "../../../assets/img/icons/running.json";
import Lottie from "lottie-react";

/**
 *
 * @param {{status: string}} data
 */
const TableTooltip = ({ status }) => {
  return (
    <Tooltip
      title={`${status?.charAt(0).toLocaleUpperCase()}${status?.slice(1)}`}
    >
      {status === "failed" || status === "cancelled" ? (
        <FailedIcon className="centered-svg-block" />
      ) : status === "success" ? (
        <SuccessIcon className="centered-svg-block" />
      ) : status === "queued" ? (
        <QueuedIcon className="centered-svg-block" />
      ) : status === "running" ? (
        <Lottie
          animationData={runningIcon}
          loop
          style={{ width: "20px", margin: "0 auto" }}
        />
      ) : (
        <span>{status}</span>
      )}
    </Tooltip>
  );
};

export default TableTooltip;
