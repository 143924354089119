import { config } from "../constants";
import axios from "../utils/axios";
import { toast } from "react-toastify";

export const fetchLogsService = async (data) => {
  try {
    const response = await axios.post(
      `${config.url.AIRFLOW}/api/airflow/get_logs`,
      data
    );

    const logs = response.data.logs;
    return logs;
  } catch (_) {}
};

export const getTargetDAGDataService = async () => {
  const toastID = toast.loading("Retrieving job data");
  let response;

  try {
    response = await axios.post(
      `${config.url.AIRFLOW}/api/airflow/list_dags`,
      {
        dag_type: "all",
      }
    );
  } catch (_) {
    toast.error("Error while loading scheduled jobs.");
  }
  toast.dismiss(toastID);
  return response;
};

export const getTasksService = async (data) => {
  const toastId = toast.loading("Fetching logs...");
  let response;

  try {
    response = await axios.post(
      `${config.url.AIRFLOW}/api/airflow/get_task_level_for_logs`,
      data
    );
  } catch (_) {
    toast.error("Error while fetching logs.");
  }
  toast.dismiss(toastId);
  return response;
};
