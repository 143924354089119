/* eslint-disable */
import React, { Component } from "react";
import { Button, OverlayTrigger, Col, Tab, Popover } from "react-bootstrap";
import { Tooltip, CircularProgress } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import * as Icon from "react-feather";
import "./taps.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import moment from "moment-timezone";
import WithRouter from "../../HOC/RouterWrapper";
import HorizontalDropdownMenu from "../../components/global/HorizontalDropdown";

import { ReactComponent as RefreshIcon } from "../../assets/img/icons/refresh.svg";
import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import { ReactComponent as StopIcon } from "../../assets/img/icons/StopIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/img/icons/CancelIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/icons/SettingsIcon.svg";
import { ReactComponent as PlayIcon } from "../../assets/img/icons/PlayIcon.svg";
import { ReactComponent as OptionsIcon } from "../../assets/img/icons/OptionsIcon.svg";

import SearchJobs from "../jobs/SearchJobs";
import TapCreatorForm from "../../components/DagCreators/TapCreatorForm";
import DagHistory from "../jobs/DagHistory";
import { fetchLastRuns } from "../functions.js";
import djlogo from "./djlogo.svg"; // Import the SVG file stored in src/pages
import airbytelogo from "./airbyte.png"; // Import the SVG file stored in src/pages
import fivetranlogo from "./fivetran.png"; // Import the SVG file stored in src/pages
import Table from "../../components/global/Table/index.js";
import TablePagination from "../../components/global/Table/Pagination.js";
import {
  createFivetranTapService,
  deleteConnectorService,
  deleteDagService,
  discoverSchemaService,
  getGACustomReportsService,
  getMyTapsService,
  saveCustomReportsService,
  stopTaskService,
  triggerAirbyteTapService,
  triggerFivetranService,
} from "../../services/taps.js";
import DeleteConnectorModal from "../../components/Modals/DeleteConnector.js";
import CustomReportsModal from "../../components/Modals/CustomReports.js";

// let GoogleLoginPage;
// if (process.env.NODE_ENV !== 'production') {
//   GoogleLoginPage = require('../../components/google_oauth/oauth.js').default;
// }

Sentry.init({
  dsn: "https://53a82b56123b43348a6579efda968a77@o4504469493645312.ingest.sentry.io/4504469494497280",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const user = JSON.parse(localStorage.getItem("user"));
var customer_timezone = localStorage.getItem("timezone");

let dropdownSelec = [];

let timestamps = [{ 5: "2 days ago" }];
const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

class NewTap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 40,
      loading: false,
      get_dags_status: true,
      spec_details: [],
      global_stream_selected: false,
      global_replicate: false,
      global_selected_sync: "",
      tap_alias: "",
      datasources: [],
      schema_name: "",
      schema: "",
      running_taps: [],
      selected_streams: [],
      global_supportedSyncModes: [],
      custom_reports: [],
      modal_custom_reports: false,
      activeTab: "mytaps",
      historical_dag_runs: [],
      replication_disabled: true,
      dag_pause_statuses: [],
      saving_settings: false,
      loading_connectors: true,
      show_schedule_button: false,
      saving_disabled: true,
      loading_mytaps: true,
      selected_tap: "",
      delete_name: "",
      selected_job: "",
      dag_runs: [],
      streams: [],
      first_load: true,
      last_refresh: timestamps,
      mytasks: [],
      delete_id: "",
      color: "primary",
      embedded_url: false,
      schema_overwritten: false,
      saving: false,
      new_schema: false,
      deleting: false,
      searchConnectorsKeyword: "",
      jobNameFilters: "",
      currentPage: 1,
      perPage: 10,
      isShowingCreateTapForm: false,
      isShowingDagHistoryTable: false,
      sortedColumn: {
        column: "",
        isAsc: true,
      },
    };
    this.isComponentMounted = false; // Flag to track if the component is mounted
  }

  componentDidUpdate(prevProps) {
    const hasQueryParamsChanged =
      this?.props?.router?.location &&
      prevProps?.router?.location &&
      this.props.router.location.search !== prevProps.router.location.search;

    const hasInitialHistoryModalView =
      prevProps.router.location.search.includes("history_modal") ||
      this.props.router.location.search.includes("history_modal");

    if (hasQueryParamsChanged) {
      if (hasInitialHistoryModalView) {
        this.showInitialHistoryModal(prevProps);
      } else {
        this.fetchInitialTapData();
      }
    }
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.fetchInitialTapData();
  }

  handleStopTask = async (display_name, datajolt_task_id) => {
    this.setState({ stopping: true, progress: 80 });

    const isSuccess = await stopTaskService(display_name, datajolt_task_id);

    if (isSuccess) {
      this.setState((prevState) => {
        const updatedDAGs = prevState.mytasks.map((dag) =>
          dag.datajolt_task_id === datajolt_task_id
            ? { ...dag, status: "canceled" }
            : dag
        );
        return { ...prevState, DAGs: updatedDAGs };
      });
    }
    this.setState({ progress: 100, stopping: false });
  };

  setSelectedTapData = (data) => this.setState(data);

  getTargetDAGData = () => {
    const history_modal = new URLSearchParams(window.location.search).get(
      "history_modal"
    );
    const targetDAG = this.state.mytasks.find((DAG) => DAG.id == history_modal);
    console.log("history_modal");
    console.log(targetDAG);
    return targetDAG;
  };

  showInitialHistoryModal = (prevProps) => {
    //prevProps to extract queryParams from pervious render

    if (
      prevProps.router.location.search.includes("history_modal") &&
      !this.props.router.location.search.includes("history_modal")
    ) {
      this.setState({
        isShowingDagHistoryTable: false,
        historical_runs_available: false,
        modal_history: false,
      });
    }

    if (
      !prevProps.router.location.search.includes("history_modal") &&
      this.props.router.location.search.includes("history_modal")
    ) {
      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }
    }
  };

  componentWillUnmount = () => {
    this.fetchDAGLastRunsTimerMounted = false;
    this.isComponentMounted = false;
    clearTimeout(this.fetchDAGLastRunsTimeout);
  };

  toggleHistoryDagTable = () => {
    this.setState({
      isShowingDagHistoryTable: !this.state.isShowingDagHistoryTable,
    });
  };

  createFivetranTap = async (
    connector_id,
    connector_name,
    schema,
    tap_alias
  ) => {
    this.setState({ progress: 40 });

    const isSuccess = await createFivetranTapService(
      connector_name,
      tap_alias,
      connector_id,
      schema
    );

    if (isSuccess) {
      this.getMyTaps();
    }
    this.setState({ progress: 100 });
  };

  fetchInitialTapData = async () => {
    let id = new URLSearchParams(window.location.search).get("id");
    let details = new URLSearchParams(window.location.search).get("details");
    let edit_tap = new URLSearchParams(window.location.search).get("edit_tap");
    let isEditingReport = new URLSearchParams(window.location.search).get(
      "custom_reports"
    );

    if (
      edit_tap &&
      edit_tap === this.state.selected_for_edit &&
      !isEditingReport
    ) {
      return;
    }

    if (
      edit_tap &&
      edit_tap === this.state.selected_for_edit &&
      isEditingReport
    ) {
      this.getGACustomReports();
      return;
    }
    if (id) {
      let tap_alias = details.split("___")[2];
      let connector_name = details.split("___")[1];
      let schema = details.split("___")[0];
      this.createFivetranTap(id, connector_name, schema, tap_alias);
    } else if (edit_tap) {
      this.setState({ selected_for_edit: edit_tap });
      this.toggleCreateTapForm();
      await this.getMyTaps();
      console.log(this.state.mytasks);
      let item = this.state.mytasks.find((item) => item.id === edit_tap);
      if (item) {
        let { connectionID, logo, sourceID, subtype } = item;
        this.setState({
          connection_id: connectionID,
          source_ID: sourceID,
          selected_tap: subtype,
          logo: logo,
          selected_for_edit: edit_tap,
          selected_source_ID: sourceID,
        });

        if (isEditingReport) {
          this.getGACustomReports();
        }
      }
    } else {
      this.setState({
        connection_id: "",
        source_ID: "",
        selected_tap: "",
        logo: "",
        selected_for_edit: "",
        isShowingCreateTapForm: false,
      });
      await this.getMyTaps();

      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }
    }
  };

  getGACustomReports = async () => {
    this.setState({ custom_reports: [] });

    const response = await getGACustomReportsService(
      this.state.selected_source_ID,
      this.state.selected_for_edit
    );

    if (response) {
      const { specs, window_in_days, custom_reports } = response;

      this.setState({
        specs,
        window_in_days,
        custom_reports,
        modal_custom_reports: true,
      });
    }
  };

  calculateTotalPages = () => {
    const totalLength = this.state.mytasks.filter((DAG) =>
      this.filterJobNames(DAG)
    ).length;
    return totalLength ? Math.ceil(totalLength / this.state.perPage) : 1;
  };

  paginateArray = (
    array,
    activePage = this.state.currentPage,
    perPageCount = this.state.perPage
  ) => {
    const startIndex = (activePage - 1) * perPageCount;
    const endIndex = startIndex + perPageCount;

    return array.slice(startIndex, endIndex);
  };

  goToSchedule = () => {
    this.setState({
      progress: 40,
      saving_settings: true,
      saving_disabled: true,
    });
    this.setState({
      progress: 100,
      saving_settings: false,
      saving_disabled: false,
      activeTab: "schedule",
    });
  };

  toggleDeleteModal = (
    connector_id,
    sourceID,
    logo,
    connector_name,
    datajolt_task_id
  ) => {
    this.setState({
      modal_delete_connector: !this.state.modal_delete_connector,
    });
    if (!this.state.modal_delete_connector) {
      this.setState({
        selected_source_ID: sourceID,
        delete_logo: logo,
        delete_name: connector_name,
        delete_id: connector_id,
        delete_datajolt_task_id: datajolt_task_id,
      });
    }
  };

  getMyTaps = (activeTab = "mytaps") => {
    return new Promise(async (resolve) => {
      this.setState({
        get_dags_status: true,
        global_replicate: false,
        selected_streams: [],
        global_stream_selected: false,
        global_selected_sync: "",
        tap_alias: "",
        loading_mytaps: true,
        progress: 60,
        logs: false,
        streams: [],
        loading_spinner: true,
        replication_disabled: true,
        activeTab: activeTab,
      });

      const response = await getMyTapsService();

      if (response) {
        this.setState({
          show_schedule_button: false,
          mytasks: response.data["dags_list"],
        });
        for (var dag in response.data["dags_list"]) {
          if (
            response.data["dags_list"][dag]["status"] == "running" ||
            response.data["dags_list"][dag]["status"] == "queued"
          ) {
            fetchLastRuns(
              response.data["dags_list"][dag]["datajolt_task_id"],
              1,
              this.setState.bind(this),
              () => this.isComponentMounted
            );
          }
          resolve();
        }
      }

      this.setState({ progress: 100, loading_mytaps: false });
    });
  };

  debounce(fn, delay) {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  }

  updateCustomReport = this.debounce((index, key, e, val) => {
    var current = [...this.state.custom_reports];
    current[index][key] = val || e.target.value;
    this.setState({ custom_reports: current });
  }, 50);

  triggerAirbyteTap = async (dag_id, connection_id, datajolt_task_id) => {
    const isSuccess = await triggerAirbyteTapService(
      dag_id,
      connection_id,
      datajolt_task_id
    );

    if (isSuccess) {
      const nowUTC = moment().utc()?.format("YYYY-MM-DD HH:mm");
      this.setState((prevState) => {
        const updatedMytaps = prevState.mytasks.map((tap) => {
          if (tap.id === dag_id) {
            return {
              ...tap,
              last_started: nowUTC,
              rows: "",
              last_ended: "None",
              status: "running",
            };
          }
          return tap;
        });
        return { mytasks: updatedMytaps };
      });
      fetchLastRuns(
        datajolt_task_id,
        1,
        this.setState.bind(this),
        () => this.isComponentMounted
      );
    }
  };

  toggle_modal_daily_hour = () => {
    this.setState({ modal_daily_hour: !this.state.modal_daily_hour });
  };

  toggle_modal_cron = () => {
    this.setState({ modal_cron: !this.state.modal_cron });
  };

  deleteConnector = async (datajolt_task_id) => {
    this.setState({ deleting: true, color: "primary", progress: 40 });

    const isSuccess = await deleteConnectorService(datajolt_task_id);

    if (isSuccess) {
      this.setState({ modal_delete_connector: false });
      this.getMyTaps();
    }

    this.setState({ deleting: false, progress: 100 });
  };

  deleteDAG = async (datajolt_task_id, alias) => {
    this.setState({ deleting: true, progress: 80 });

    const isSuccess = await deleteDagService(alias, datajolt_task_id);

    if (isSuccess) {
      this.getMyTaps();
    }

    this.setState({
      progress: 100,
      modal_delete_connector: false,
      deleting: false,
    });
  };

  triggerFivetran = async (datajolt_task_id, alias) => {
    this.setState({ progress: 40 });

    const isSuccess = await triggerFivetranService(alias, datajolt_task_id);

    if (isSuccess) {
      const nowUTC = moment().utc()?.format("YYYY-MM-DD HH:mm");
      this.setState((prevState) => {
        const updatedMytaps = prevState.mytasks.map((tap) => {
          if (tap.datajolt_task_id === datajolt_task_id) {
            return {
              ...tap,
              last_started: nowUTC,
              rows: "",
              last_ended: "None",
              status: "running",
            };
          }
          return tap;
        });
        return { mytasks: updatedMytaps };
      });
      fetchLastRuns(
        datajolt_task_id,
        1,
        this.setState.bind(this),
        () => this.isComponentMounted
      );
    }

    this.setState({ progress: 100 });
  };

  validate_custom_reports = () => {
    const isUnique =
      [...new Set(this.state.custom_reports.map((obj) => obj.name))].length ===
      this.state.custom_reports.length;
    if (!isUnique) {
      toast.error("Custom report names have to be unique", { autoClose: 5000 });
      return false;
    } else {
      for (let obj of this.state.custom_reports) {
        let dimensions = [];
        let metrics = [];
        if (!Array.isArray(obj["dimensions"])) {
          dimensions = obj["dimensions"].split(",");
        } else {
          dimensions = obj["dimensions"];
        }
        if (!Array.isArray(obj["metrics"])) {
          metrics = obj["metrics"].split(",");
        } else {
          metrics = obj["metrics"];
        }

        if (obj["name"] == "") {
          toast.error("Your custom report name cannot be empty.", {
            autoClose: 5000,
          });
          return false;
        } else if (dimensions.length == 0 || dimensions == "") {
          toast.error(
            "Your " +
              obj["name"] +
              " custom report dimensions cannot be empty.",
            { autoClose: 5000 }
          );
          return false;
        } else if (metrics.length == 0 || metrics == "") {
          toast.error(
            "Your " + obj["name"] + " custom report metrics cannot be empty.",
            { autoClose: 5000 }
          );
          return false;
        } else if (dimensions.length > 9) {
          toast.error(
            "Your " +
              obj["name"] +
              " custom report cannot have more than 9 dimensions.",
            { autoClose: 5000 }
          );
          return false;
        } else if (metrics.length > 10) {
          toast.error(
            "Your " +
              obj["name"] +
              " custom report cannot have more than 10 metrics.",
            { autoClose: 5000 }
          );
          return false;
        }
      }
      return true;
    }
  };

  discoverOrUpdate = (type) => {
    this.setState({
      global_replicate: false,
      selected_streams: [],
      global_selected_sync: "",
      global_stream_selected: false,
    });
    if (type == "schedule") {
      this.setState({ selected_for_edit: false });
      this.discoverSchema(this.state.selected_source_ID, "schedule");
    }
  };

  discoverSchema = async (sourceID, type) => {
    if (type != "schedule") {
      this.setState({ show_schedule_button: false });
    }
    this.setState({
      streams: [],
      selected_source_ID: sourceID,
      selection_type: type,
      saving_disabled: true,
      progress: 40,
      replication_disabled: false,
      loading_spinner: true,
      activeTab: "data",
    });
    const SelectedTap = {
      sourceID: sourceID,
      tap_name: this.state.selected_tap,
      destination: false,
    };

    const response = await discoverSchemaService(
      SelectedTap,
      this.state.selected_tap
    );

    if (response) {
      this.setState({
        saving_disabled: false,
        streams: response.data["streams"],
        catalogID: response.data["catalogID"],
        global_supportedSyncModes: response.data["global_supportedSyncModes"],
      });
    } else {
      this.setState({ spec_details: [] });
    }

    this.setState({ progress: 100, loading_spinner: false });
  };

  saveCustomReports = async () => {
    if (this.validate_custom_reports()) {
      this.setState({ saving_new_specs: true });
      let current_specs = JSON.parse(JSON.stringify(this.state.specs));
      current_specs.connectionConfiguration.window_in_days =
        this.state.window_in_days;
      current_specs.connectionConfiguration.custom_reports = JSON.stringify(
        this.state.custom_reports
      );

      const isSuccess = await saveCustomReportsService(
        current_specs,
        this.state.selected_for_edit
      );

      if (isSuccess) {
        this.setState({ modal_custom_reports: false });
        this.discoverOrUpdate(this.state.selection_type);
      }

      this.props.router.navigate(
        `/taps/mytaps?edit_tap=${this.state.selected_for_edit}`
      );
      this.setState({ loading: false, saving_new_specs: false });
    }
  };

  saveOrSchedule = (type) => {
    if (type == "schedule") {
      this.goToSchedule();
    }
  };

  handleStateUpdate = (newState) => {
    this.setState(newState);
  };

  toggleCreateTapForm = () => {
    this.setState(
      {
        isShowingCreateTapForm: !this.state.isShowingCreateTapForm,
      },
      () => {
        if (!this.state.isShowingCreateTapForm) {
          this.props.router.navigate("/taps/mytaps");
        }
      }
    );
  };

  redirectToHistoryModalView = (DAG, event) => {
    // Prevent any parent click events from interfering
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log("History modal triggered for DAG ID: ", DAG.id);
    this.props.router.navigate(`/taps/mytaps?history_modal=${DAG.id}`);
  };

  updateSearchConnectorsKeyword = (e) => {
    this.setState({ searchConnectorsKeyword: e.target.value });
  };
  handleClearSearchConnectorsKeyword = () => {
    this.setState({ searchConnectorsKeyword: "" });
  };

  updateJobNameFilters = (filters) =>
    this.setState({ jobNameFilters: filters });

  filterJobNames = (DAG) => {
    const target = DAG.id;
    if (this.state.jobNameFilters) {
      if (target.includes(this.state.jobNameFilters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  onSortColumn = (column) => {
    const tasks = this.state.mytasks.map((task) => ({ ...task }));
    let isAsc = true;

    if (this.state.sortedColumn.column === column) {
      isAsc = !this.state.sortedColumn.isAsc;
    }

    this.setState({
      mytasks: tasks.sort((a, b) => {
        if (isAsc) {
          return a[column]?.toLowerCase() < b[column]?.toLowerCase() ? 1 : -1;
        } else {
          return b[column]?.toLowerCase() < a[column]?.toLowerCase() ? 1 : -1;
        }
      }),
    });
    this.setState({ sortedColumn: { column, isAsc } });
  };

  render() {
    const { navigate } = this.props.router;
    return (
      <div>
        <LoadingBar color="#0245a0" progress={this.state.progress} />
        <div className="container-fluid py-3">
          <Helmet title="Datajolt | Data Taps" />
          <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "12px 8px",
                height: "45px",
              }}
            >
              <h4 style={{ marginBottom: 0 }}>
                <b style={{ color: "#090E47", marginRight: "10px" }}>
                  My Data Taps
                  {!this.state.loading_mytaps
                    ? " (" + this.state.mytasks.length + ")"
                    : ""}
                </b>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id={`popover-positioned-bottom`}>
                      <Popover.Header as="h3">
                        {"What are Data Taps?"}
                      </Popover.Header>
                      <Popover.Body>
                        Data Taps are source systems creating your business
                        data.
                        <br />
                        <br />
                        This page allows you to create a data refresh from your
                        source systems to your DWH so your data can then be
                        transformed and analysed.
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <DetailsIcon />
                </OverlayTrigger>{" "}
              </h4>

              {this.state.activeTab === "mytaps" && (
                <div
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  {this.state.isShowingCreateTapForm &&
                  this.state.logo &&
                  this.state.selected_tap ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <img
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                        src={this.state.logo}
                      />
                      <b
                        style={{
                          marginBottom: "0",
                          fontWeight: "bold",
                          color: "#090E47",
                          fontSize: "18px",
                        }}
                      >
                        {this.state.selected_tap}
                      </b>
                    </span>
                  ) : (
                    <>
                      <Tooltip title="Refresh Taps" placement="bottom">
                        <div
                          className="me-1 mb-1 mr-2"
                          disabled={this.state.loading}
                          onClick={() => this.componentDidMount()}
                        >
                          {this.state.loading_mytaps ? (
                            <CircularProgress size={16} sx={{ color: "#2c49fc" }} />
                          ) : (
                            <RefreshIcon />
                          )}
                        </div>
                      </Tooltip>
                      <Button
                        variant="generaltabs"
                        className="active lighter-hover"
                        onClick={this.toggleCreateTapForm}
                        style={{
                          padding: "5px 7px",
                          borderRadius: "5px",
                          background: "#2D49FD",
                        }}
                      >
                        Create a Tap{" "}
                        <Icon.RefreshCw
                          style={{ marginLeft: "5px" }}
                          width={14}
                          color="white"
                        />
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="mytaps">
                <hr style={{ margin: "0 8px" }} />
                <Col md="12">
                  {!this.state.loading_mytaps &&
                  this.state.mytasks.length == 0 ? (
                    <>
                      <br />
                      <br />
                      <center>
                        <h4>You currently have no data taps.</h4>
                        <br />
                      </center>
                      <br />
                    </>
                  ) : (
                    <>
                      <Table
                        headings={[
                          {
                            width: "6%",
                            columnName: "logo",
                            isSortable: false,
                            Children: () => <></>,
                          },
                          {
                            columnName: "id",
                            isSortable: true,
                            Children: ({ children }) => (
                              <div className="d-flex justify-content-start gap-1 align-items-center h-100">
                                <span>Job Name </span>
                                <SearchJobs
                                  onFilterChange={this.updateJobNameFilters}
                                />
                                {children}
                              </div>
                            ),
                          },
                          {
                            columnName: "source",
                            isSortable: true,
                            Children: ({ children }) => (
                              <span className="d-flex align-items-center h-100">
                                Source
                                {children}
                              </span>
                            ),
                          },
                          {
                            columnName: "schema",
                            isSortable: true,
                            Children: ({ children }) => (
                              <span className="d-flex align-items-center h-100">
                                Schema
                                {children}
                              </span>
                            ),
                          },
                          {
                            columnName: "last_started",
                            isSortable: true,
                            Children: ({ children }) => (
                              <span className="d-flex align-items-center h-100">
                                last started
                                {children}
                              </span>
                            ),
                          },
                          {
                            columnName: "last_ended",
                            isSortable: true,
                            Children: ({ children }) => (
                              <span className="d-flex align-items-center h-100">
                                last ended
                                {children}
                              </span>
                            ),
                          },
                          {
                            columnName: "status",
                            isSortable: true,
                            Children: ({ children }) => (
                              <span className="d-flex align-items-center h-100">
                                status
                                {children}
                              </span>
                            ),
                          },
                          {
                            columnName: "provider",
                            isSortable: false,
                            Children: () => "provider",
                          },
                          {
                            width: "6%",
                            columnName: "actions",
                            isSortable: false,
                            Children: () => "actions",
                          },
                        ]}
                        rows={this.paginateArray(
                          this.state.mytasks?.filter((DAG) =>
                            this.filterJobNames(DAG)
                          )
                        ).map((DAG, index) => ({
                          key: `${DAG.id}${index}`,
                          columns: [
                            {
                              type: "image",
                              logo: DAG.logo,
                            },
                            {
                              type: "text",
                              text: DAG.id,
                            },
                            {
                              type: "text",
                              text: DAG.source,
                            },
                            {
                              type: "text",
                              text: DAG.schema,
                            },
                            {
                              type: "date",
                              date: DAG.last_started,
                            },
                            {
                              type: "date",
                              date: DAG.last_ended,
                            },
                            {
                              type: "status",
                              key: DAG.status,
                            },
                            {
                              type: "component",
                              component: (
                                <img
                                  src={
                                    DAG.provider === "Fivetran"
                                      ? fivetranlogo
                                      : DAG.provider == "AB Hosted"
                                      ? airbytelogo
                                      : djlogo
                                  }
                                  width="30px"
                                  alt={`${DAG.provider || "Default"} Logo`}
                                  style={{ display: "block" }}
                                />
                              ),
                            },
                            {
                              type: "component",
                              component: (
                                <HorizontalDropdownMenu
                                  items={[
                                    {
                                      tooltipTitle: "Trigger Tap",
                                      disabled: DAG.status === "running",
                                      variant: "no-outline-success",
                                      onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        DAG.provider == "Fivetran"
                                          ? this.triggerFivetran(
                                              DAG.datajolt_task_id,
                                              DAG.id
                                            )
                                          : this.triggerAirbyteTap(
                                              DAG.id,
                                              DAG.connectionID,
                                              DAG.datajolt_task_id
                                            );
                                      },
                                      children:
                                        DAG.status === "running" ? (
                                          <CircularProgress
                                            size={12}
                                            sx={{ color: "#2c49fc" }}
                                          />
                                        ) : (
                                          <PlayIcon />
                                        ),
                                    },
                                    {
                                      tooltipTitle: "Edit Tap",
                                      disabled: DAG.provider !== "AB Hosted",
                                      className: "me-1 mb-1",
                                      variant: "no-outline",
                                      onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        navigate(
                                          "/taps/mytaps?edit_tap=" + DAG.id
                                        );
                                      },
                                      children: (
                                        <SettingsIcon className="bigger-svg" />
                                      ),
                                    },
                                    {
                                      tooltipTitle: "Open History",
                                      disabled: false,
                                      className: "me-1 mb-1",
                                      variant: "no-outline",
                                      onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.redirectToHistoryModalView(DAG, e);
                                      },
                                      children: <OptionsIcon />,
                                    },
                                    {
                                      tooltipTitle: "Stop Tap",
                                      disabled: false,
                                      className: "me-1 mb-1",
                                      variant: "no-outline-danger",
                                      onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.handleStopTask(
                                          DAG.id,
                                          DAG.datajolt_task_id
                                        );
                                      },
                                      children: <StopIcon />,
                                    },
                                    {
                                      tooltipTitle: "Delete Tap",
                                      disabled: false,
                                      className: "mb-1",
                                      variant: "no-outline-danger",
                                      onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.toggleDeleteModal(
                                          DAG.id,
                                          DAG.sourceID,
                                          DAG.logo,
                                          DAG.subtype,
                                          DAG.datajolt_task_id
                                        );
                                      },
                                      children: <CancelIcon />,
                                    },
                                  ]}
                                />
                              ),
                            },
                          ],
                        }))}
                        sortedColumn={this.state.sortedColumn}
                        onSortColumn={(columnName) =>
                          this.onSortColumn(columnName)
                        }
                        isLoading={this.state.loading_mytaps}
                      />
                      <TablePagination
                        currentPage={this.state.currentPage}
                        totalPages={this.calculateTotalPages()}
                        updatePageNum={(num) =>
                          this.setState({ currentPage: num })
                        }
                        updatePerPage={(num) => this.setState({ perPage: num })}
                        activeTable={this.state.jobNameFilters}
                      />
                    </>
                  )}
                </Col>
              </Tab.Pane>
            </Tab.Content>

            <CustomReportsModal
              isShown={this.state.modal_custom_reports}
              onHide={() => {
                this.setState({ modal_custom_reports: false });
                navigate(
                  `/taps/mytaps?edit_tap=${this.state.selected_for_edit}`
                );
              }}
              onAction={() => this.saveCustomReports()}
              logo={this.state.logo}
              windowInDays={this.state.window_in_days}
              setWindwInDays={(e) =>
                this.setState({
                  window_in_days: parseInt(e.target.value, 10),
                })
              }
              customReports={this.state.custom_reports}
              addCustomReport={() =>
                this.setState({
                  custom_reports: [
                    ...this.state.custom_reports,
                    { name: "", metrics: "", dimensions: "" },
                  ],
                })
              }
              selectedTap={this.state.selected_tap}
              savingNewSpecs={this.state.saving_new_specs}
              updateCustomReport={(i, type, e) =>
                this.updateCustomReport(i, type, e)
              }
              deleteReport={() =>
                this.setState({
                  custom_reports: this.state.custom_reports.filter(
                    (item) => item.id !== custom_report.id
                  ),
                })
              }
            />

            <DeleteConnectorModal
              isShown={this.state.modal_delete_connector}
              onHide={() => this.toggleDeleteModal()}
              onAction={() =>
                this.deleteDAG(
                  this.state.delete_datajolt_task_id,
                  this.state.delete_id
                )
              }
              deleteData={{
                logo: this.state.delete_logo,
                deleting: this.state.deleting,
              }}
              text={{
                bold: `${this.state.delete_name} (${this.state.delete_id})`,
                regular: "data tap.",
              }}
            />
          </Tab.Container>

          <CSSTransition
            in={this.state.isShowingDagHistoryTable}
            classNames="form-tab"
            timeout={300}
            unmountOnExit
          >
            <DagHistory
              dagList={Boolean(this.state.mytasks.length) && this.state.mytasks}
              onClose={() => {
                this.setState({
                  isShowingDagHistoryTable: false,
                });
                navigate("/taps/mytaps");
              }}
            />
          </CSSTransition>

          <CSSTransition
            in={this.state.isShowingCreateTapForm}
            classNames="form-tab"
            timeout={300}
            unmountOnExit
          >
            <TapCreatorForm
              getMyTaps={this.getMyTaps}
              mytasks={this.state.mytasks} // Pass mytasks as a prop
              setSelectedTapData={this.setSelectedTapData}
              isEditingTap={new URLSearchParams(window.location.search).get(
                "edit_tap"
              )}
              toggleCreateTapForm={this.toggleCreateTapForm}
            />
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default WithRouter(NewTap);
