import React from "react";
import { Stack, Typography } from "@mui/material";

const Footer = () => (
  <Stack
    component={"footer"}
    direction={"row"}
    borderTop={"1px solid #dee6ed"}
    bgcolor={"#fff"}
    paddingBlock={"1rem"}
    paddingInline={"0.75rem"}
  >
    <Typography
      variant="body1"
      fontSize={"11px"}
      marginInlineStart={"auto"}
      fontFamily={"Poppins"}
      color={"#737d85 !important"}
      fontWeight={"300"}
    >
      &copy; {new Date().getFullYear()} -{" "}
      <Typography fontSize={"11px"} fontWeight={"300"} variant="caption" color={"##737d85 !important"} fontFamily={"Poppins"}>
        Datajolt v0.985
      </Typography>
    </Typography>
  </Stack>
);

export default Footer;
