import React, { useRef, useContext } from "react";
import { OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import * as Icon from "react-feather";
import { Accordion, AccordionSummary, AccordionDetails, CircularProgress } from "@mui/material";

import { useSelector } from "react-redux";

import _ from "lodash";
import { ResizableBox } from "react-resizable";
import { HeightContext } from "./LeftBoxes";

const box_titles_style = {
  borderColor: "#dee6ed",
  color: "#448593",
  fontSize: "12px",
  fontWeight: "500",
  minHeight: "5px",
  marginTop: "3px",
};

export default function EditorHistoryBox({
  getFileHistory,
  openFile,
  loading_file_history,
  activeBranch,
}) {
  const editor_height = document.documentElement.clientHeight - 136;
  const historyREF = useRef(null);
  const activeTab = useSelector((state) => state.activeTab);
  const files_history = useSelector((state) => state.files_history);
  const selected_shas = useSelector((state) => state.selected_shas);
  const {
    height: { history: currentHeight, schema: schemaHeight },
    onResizeBox,
    collapsed: { queries: isQueriesCollapsed, history: isHistoryCollapsed },
  } = useContext(HeightContext);

  const lockScrolling = () => {
    const schemaWrapper = document.getElementById("schema-editor-helper-box");
    const queriesWrapper = document.getElementById("queries-editor-helper-box");
    const historyWrapper = document.getElementById("history-editor-helper-box");

    schemaWrapper.style.overflow = "hidden";
    queriesWrapper.style.overflow = "hidden";
    historyWrapper.style.overflow = "hidden";
  };

  const unlockScrolling = () => {
    const schemaWrapper = document.getElementById("schema-editor-helper-box");
    const queriesWrapper = document.getElementById("queries-editor-helper-box");
    const historyWrapper = document.getElementById("history-editor-helper-box");

    schemaWrapper.style.overflow = "auto";
    queriesWrapper.style.overflow = "auto";
    historyWrapper.style.overflow = "auto";
  };

  return (
    <ResizableBox
      height={!isHistoryCollapsed ? currentHeight : 48}
      resizeHandles={["n"]}
      onResizeStart={lockScrolling}
      className={`dragged-box bottom-aligned left-aligned react-resizable helper-editor-resizable ${
        (isHistoryCollapsed || isQueriesCollapsed) && "no-resize"
      }`}
      style={{
        background: "transparent",
        border: "none",
      }}
      onResizeStop={unlockScrolling}
      onResize={(_, { size: { height } }) => onResizeBox("history", height)}
      maxConstraints={[Infinity, editor_height - schemaHeight - 48]}
      minConstraints={[10, 48]}
    >
      <Accordion
        id="history-editor-helper-box"
        expanded={!isHistoryCollapsed}
        onChange={() => {
          if (!isHistoryCollapsed) {
            if (historyREF?.current && historyREF?.current?.style) {
              historyREF.current.style.height = 0;
            }
            onResizeBox("history", -2);
          } else {
            if (historyREF?.current && historyREF?.current?.style) {
              historyREF.current.style.height = "unset";
            }
            onResizeBox("history", -1);
          }
        }}
        className="schema-list helper-list"
        sx={{
          boxShadow: "none",
          height: !isHistoryCollapsed ? "100%" : 48,
          ...(isHistoryCollapsed && { overflow: "hidden" }),

          "& .MuiAccordionSummary-root": {
            minHeight: "46px !important",
          },
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ display: "flex", color: "#448593", alignItems: "baseline" }}
        >
          <Icon.ChevronRight
            size={18}
            style={{
              marginTop: "3px",
              transform: !isHistoryCollapsed ? "rotate(90deg)" : "unset",
              transition: "all 0.2s ease",
              marginRight: "5px",
            }}
          />
          <span style={box_titles_style}>File History</span>
          {!isHistoryCollapsed && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-bottom-helper-box`}>
                  Refresh File History
                </Tooltip>
              }
            >
              <Icon.RefreshCw
                size={16}
                role="button"
                style={{ marginTop: "4px", marginLeft: "auto" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!loading_file_history) {
                    getFileHistory(activeTab);
                  }
                }}
              />
            </OverlayTrigger>
          )}
        </AccordionSummary>
        <AccordionDetails
          ref={historyREF}
          sx={{ height: editor_height / 3 - 50, padding: 0 }}
        >
          <div>
            <Card className="flex-fill w-60" style={{ height: "1px" }}>
              <Card.Body className="d-flex">
                {!loading_file_history ? (
                  files_history[activeTab] ? (
                    <ul className="timeline m-0">
                      {files_history[activeTab].map((history, i) => (
                        <li
                          key={i}
                          className={
                            selected_shas[activeTab] === history.sha
                              ? "timeline-item-selected"
                              : "timeline-item"
                          }
                          onClick={() =>
                            openFile(activeBranch, activeTab, history.sha)
                          }
                        >
                          <strong>{history.date}</strong>
                          <p className="text-sm">{history.author}</p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )
                ) : (
                  <>
                    <center
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress size={16} color="#fff" />
                    </center>
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </AccordionDetails>
      </Accordion>
    </ResizableBox>
  );
}
