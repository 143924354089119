import React, { Component, createRef } from "react";
import { useAuth, useTenantsState } from "@frontegg/react";
import {
  Popover,
  Button,
  Tab,
  OverlayTrigger,
} from "react-bootstrap";
import { Tooltip, CircularProgress } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import { config } from "../../constants";
import * as Icon from "react-feather";
import { Helmet } from "react-helmet-async";
import LoadingBar from "react-top-loading-bar";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import WithRouter from "../../HOC/RouterWrapper";
import HorizontalDropdownMenu from "../../components/global/HorizontalDropdown";

import { ReactComponent as RefreshIcon } from "../../assets/img/icons/refresh.svg";
import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import { ReactComponent as StopIcon } from "../../assets/img/icons/StopIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/img/icons/CancelIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/icons/SettingsIcon.svg";
import { ReactComponent as PlayIcon } from "../../assets/img/icons/PlayIcon.svg";

import SearchJobs from "./SearchJobs";
import PushCreatorForm from "../../components/DagCreators/PushCreatorForm";
import DagHistory from "./DagHistory";
import Table from "../../components/global/Table";
import TablePagination from "../../components/global/Table/Pagination";
import {
  deletePushService,
  fetchAirflowDagsService,
  fetchDagLastRunsService,
  stopDagService,
  triggerDagService,
} from "../../services/push";
import DeleteConnectorModal from "../../components/Modals/DeleteConnector";

Sentry.init({
  dsn: "https://53a82b56123b43348a6579efda968a77@o4504469493645312.ingest.sentry.io/4504469494497280",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const TenantContextComponent = (props) => {
  const { user } = useAuth();
  const { tenants } = useTenantsState();
  const activeTenant = tenants.find((t) => t.tenantId === user.tenantId);

  return <Push {...props} activeTenant={activeTenant} />;
};

class Push extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields_mapping: [{ source: "", target: "" }],
      loading: false,
      loading_destination_objects: false,
      EditOpen: false,
      datasources: [],
      progress: 40,
      spec_details: [],
      running_dags: [],
      DAGs: [],
      loading_my_destinations: false,
      loading_connectors: false,
      dag_runs: [],
      destination_id: "",
      historical_dag_runs: [],
      subelements: false,
      selected_datapoint_source: false,
      json_update_endpoint: false,
      source_link: false,
      destination_link: false,
      logs_schedule: false,
      get_dags_status: true,
      push_type: false,
      selected_destination_object: false,
      selected_datapoint_destination: false,
      destination_fields: [],
      modal_history: false,
      activeTab: "all",
      deleting_push_alias: "",
      push_alias: "",
      destination_alias: "",
      mytaps: [],
      modal_edit: false,
      modal_flow: false,
      selected_job: "",
      scheduling: false,
      schedule_day: false,
      jobNameFilters: "",
      currentPage: 1,
      perPage: 10,
      isShowingCreatePushForm: false,
      columnsWidth: [0, 0, 0, 0, 0, 0],
      sortedColumn: {
        column: "",
        isAsc: true,
      },
    };

    this.tableRef = createRef(null);
  }

  componentDidUpdate(prevProps, prevState) {
    const hasQueryParamsChanged =
      this?.props?.router?.location &&
      prevProps?.router?.location &&
      this.props?.router.location.search !== prevProps?.router.location.search;

    const hasInitialHistoryModalView =
      prevProps?.router.location.search.includes("history_modal") ||
      this.props?.router.location.search.includes("history_modal");

    const hasInitialEditView =
      prevProps?.router.location.search.includes("edit_push") ||
      this.props?.router.location.search.includes("edit_push");

    if (hasQueryParamsChanged) {
      if (hasInitialHistoryModalView) {
        this.showInitialHistoryModal(prevProps);
      }

      if (hasInitialEditView) {
        this.showInitialEditView(prevProps);
      }
    }

    if (!prevState.DAGs.length && this.state.DAGs.length) {
      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }

      if (this.getTargetEditedDAGData()) {
        if (!this.state.isShowingCreatePushForm) {
          this.toggleCreatePushForm();
        }
      }
    }
  }

  setSelectedPushData = (data) => this.setState(data);

  toggleCreatePushForm = () => {
    this.setState(
      {
        isShowingCreatePushForm: !this.state.isShowingCreatePushForm,
      },
      () => {
        if (!this.state.isShowingCreatePushForm) {
          this.props?.router.navigate("/jobs/push");
        }
      }
    );
  };

  setdbturl = () => {
    console.log("Setting DBT URL");
    const localUrl = "http://127.0.0.1:5000";
    const sqlEditorUrl = config.url.SQL_EDITOR_URL;

    const waitForTenant = setInterval(() => {
      if (this.props.activeTenant?.name) {
        clearInterval(waitForTenant);
        const dbtUrl =
          sqlEditorUrl === localUrl
            ? localUrl
            : `${sqlEditorUrl}/${this.props.activeTenant.name}-dbt`;
        console.log("DBT URL:", dbtUrl);
        this.setState({ dbt_url: dbtUrl });
      }
    }, 500); // Check every 100ms
  };

  getTargetEditedDAGData = () => {
    const edit_push_id = new URLSearchParams(window.location.search).get(
      "edit_push"
    );
    const targetDAG = this.state.DAGs.find((DAG) => DAG.id === edit_push_id);
    return targetDAG;
  };

  getTargetDAGData = () => {
    const history_modal = new URLSearchParams(window.location.search).get(
      "history_modal"
    );
    const targetDAG = this.state.DAGs.find((DAG) => DAG.id === history_modal);
    return targetDAG;
  };

  toggleHistoryDagTable = () => {
    this.setState({
      isShowingDagHistoryTable: !this.state.isShowingDagHistoryTable,
    });
  };

  showInitialHistoryModal = (prevProps) => {
    //prevProps to extract queryParams from pervious render
    if (
      prevProps?.router.location.search.includes("history_modal") &&
      !this.props?.router.location.search.includes("history_modal")
    ) {
      this.setState({
        historical_runs_available: false,
        modal_history: false,
        isShowingDagHistoryTable: false,
      });
    }

    if (
      !prevProps?.router.location.search.includes("history_modal") &&
      this.props?.router.location.search.includes("history_modal")
    ) {
      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }
    }
  };

  showInitialEditView = (prevProps) => {
    //prevProps to extract queryParams from pervious render
    if (
      prevProps?.router.location.search.includes("edit_push") &&
      !this.props?.router.location.search.includes("edit_push")
    ) {
      this.setState({
        activeTab: "all",
        isShowingCreatePushForm: false,
      });
    }

    if (
      !prevProps?.router.location.search.includes("edit_push") &&
      this.props?.router.location.search.includes("edit_push")
    ) {
      if (!this.state.isShowingCreatePushForm) {
        this.toggleCreatePushForm();
      }
    }
  };

  redirectToHistoryModalView = (DAG) => {
    this.props?.router.navigate(`/jobs/push?history_modal=${DAG.id}`);
  };

  handleToggle = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  calculateTotalPages = () => {
    const totalLength = this.state.DAGs.filter((DAG) =>
      this.filterJobNames(DAG)
    ).length;
    return totalLength ? Math.ceil(totalLength / this.state.perPage) : 1;
  };

  paginateArray = (
    array,
    activePage = this.state.currentPage,
    perPageCount = this.state.perPage
  ) => {
    const startIndex = (activePage - 1) * perPageCount;
    const endIndex = startIndex + perPageCount;

    return array.slice(startIndex, endIndex);
  };

  async componentDidMount() {
    this.setState({ progress: 70 });
    await this.fetchAirflowDags();
    this.setState({ progress: 100 }, () => {});
    this.fetchDAGLastRunsTimerMounted = true;
    this.fetchDAGLastRunsTimeout = this.fetchDAGLastRunsTimer();
    this.setdbturl();

    // const resizableColumnsWidth = this.tableRef.current.clientWidth * 0.83;
    // const lastColumnWidth = this.tableRef.current.clientWidth * 0.05;
    // const eachColumnWidth = resizableColumnsWidth / 5.4;
    // this.setState({
    //   columnsWidth: [...new Array(5).fill(eachColumnWidth), lastColumnWidth],
    // });
  }

  componentWillUnmount = () => {
    this.fetchDAGLastRunsTimerMounted = false;
    clearTimeout(this.fetchDAGLastRunsTimeout);
  };

  fetchDAGLastRunsTimer = (dag_id, no, bool, time) => {
    if (dag_id)
      setTimeout(() => {
        this.fetchDAGLastRunsTimerMounted &&
          this.fetchDAGLastRuns(dag_id, no, bool);
      }, time);
  };

  toggleEditModal = (DAG) => {
    this.setState({ selected_job: DAG, modal_edit: !this.state.modal_edit });
  };

  fetchAirflowDags = async () => {
    this.setState({
      editing: false,
      get_dags_status: true,
      DAGs: [],
      loading: true,
      loading_spinner_pushes: true,
      progress: 60,
    });

    const response = await fetchAirflowDagsService();

    if (response) {
      this.setState({
        loading: false,
        DAGs: response.data["dags_list"],
        options: [],
      });
    }

    this.setState({
      progress: 100,
      loading: false,
      loading_spinner_pushes: false,
    });
  };

  stopDAG = async (DAG_id, dag_run_id) => {
    this.setState({ stopping: true });
    this.setState({ progress: 80 });

    await stopDagService(DAG_id, dag_run_id);

    this.setState({ progress: 100, stopping: false });
  };

  toggleDeletepushmodal = (alias, push_id) => {
    this.setState({
      delete_push_id: push_id,
      deleting_push_alias: alias,
      modal_delete_push: !this.state.modal_delete_push,
    });
  };

  toggleDeletedestinationmodal = (destination_name, alias, destination_id) => {
    this.setState({
      delete_destination_id: destination_id,
      deleting_destination_alias: alias,
      destinaton_to_delete: destination_name,
      modal_delete_destination: !this.state.modal_delete_destination,
    });
  };

  triggerDAG = async (dag_id) => {
    this.setState({ running_dags: [...this.state.running_dags, dag_id] });
    await triggerDagService(dag_id);
    this.fetchDAGLastRuns(dag_id, 1, false);
  };

  deletePush = async (push_alias, push_id) => {
    this.setState({ deleting_push: true });

    const isSuccess = await deletePushService(
      this.state.deleting_push_alias,
      push_id,
      push_alias
    );

    if (isSuccess) {
      this.fetchAirflowDags();
      this.toggleDeletepushmodal("");
    }

    this.setState({ deleting_push: false });
  };

  updateJobNameFilters = (filters) =>
    this.setState({ jobNameFilters: filters });

  filterJobNames = (DAG) => {
    const target = DAG.id;
    if (this.state.jobNameFilters) {
      if (target.includes(this.state.jobNameFilters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  fetchDAGLastRuns = async (dag_id, page_limit, toastId) => {
    this.setState({ fetching_last_runs: true });

    const response = await fetchDagLastRunsService(dag_id, page_limit);

    if (response) {
      if (!response.data["runs"].length) {
        this.setState({ historical_runs_available: false });
      } else {
        if (page_limit > 1) {
          this.setState({
            historical_runs_available: true,
            historical_dag_runs: {
              ...this.state.historical_dag_runs,
              [dag_id]: response.data["runs"],
            },
          });
        } else {
          this.setState({
            dag_runs: {
              ...this.state.dag_runs,
              [dag_id]: response.data["runs"],
            },
          });
        }
        if (
          (response.data["runs"][0].state === "running" ||
            response.data["runs"][0].state === "queued") &&
          this.state.get_dags_status
        ) {
          this.fetchDAGLastRunsTimer(dag_id, 1, false, 10000);
        } else {
          this.setState({
            running_dags: this.state.running_dags.filter(
              (dag) => dag !== dag_id
            ),
          });
        }
      }
    }

    this.setState({ fetching_last_runs: false, progress: 100 });
  };

  onSortColumn = (column) => {
    const dags = this.state.DAGs.map((dag) => ({ ...dag }));
    let isAsc = true;

    if (this.state.sortedColumn.column === column) {
      isAsc = !this.state.sortedColumn.isAsc;
    }

    this.setState({
      DAGs: dags.sort((a, b) => {
        if (isAsc) {
          return a[column]?.toLowerCase() < b[column]?.toLowerCase() ? 1 : -1;
        } else {
          return b[column]?.toLowerCase() < a[column]?.toLowerCase() ? 1 : -1;
        }
      }),
    });
    this.setState({ sortedColumn: { column, isAsc } });
  };

  render() {
    const { navigate } = this.props?.router;

    return (
      <div className="container-fluid py-3">
        <LoadingBar color="#0245a0" progress={this.state.progress} />
        <Helmet title="Datajolt | Data Pushes" />
        <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "12px 8px",
              height: "45px",
            }}
          >
            <h4 style={{ margin: 0 }}>
              <b style={{ color: "#090E47", marginRight: "10px" }}>
                My Data Pushes{" "}
                {this.state.loading ? "" : "(" + this.state.DAGs.length + ")"}
              </b>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Header as="h3">
                      {"What are Pushes?"}
                    </Popover.Header>
                    <Popover.Body>
                      Pushes help you push the data into the destination where
                      it will be consumed.
                      <br />
                      There can be several pushes to the same destination.
                    </Popover.Body>
                  </Popover>
                }
              >
                <DetailsIcon />
              </OverlayTrigger>
            </h4>

            {this.state.activeTab === "all" && (
              <>
                <center style={{ marginLeft: "auto" }}>
                  <Tooltip title="Refresh Pushes" placement="bottom">
                    {this.state.loading_spinner_pushes ? (
                      <CircularProgress size={16} sx={{ color: "#2c49fc" }} />
                    ) : (
                      <RefreshIcon onClick={() => this.componentDidMount()} />
                    )}
                  </Tooltip>

                  <Button
                    className="lighter-hover"
                    style={{
                      marginLeft: "10px",
                      padding: "5px 7px",
                      borderRadius: "5px",
                      background: "#2D49FD",
                    }}
                    onClick={this.toggleCreatePushForm}
                  >
                    Create a push{" "}
                    <Icon.UploadCloud
                      style={{ marginLeft: "5px" }}
                      size={14}
                      color="white"
                    />
                  </Button>
                </center>
              </>
            )}
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="all">
              <hr
                style={{
                  margin: "0 8px",
                }}
              />
              {!this.state.loading_spinner_pushes &&
              this.state.DAGs.length === 0 ? (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <center>
                    <h4>You currently have no data push set up.</h4>
                    <br />
                    <Button
                      variant="generaltabs"
                      onClick={() => this.toggleCreatePushForm()}
                      className="active"
                    >
                      Create your first push now
                    </Button>
                  </center>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              ) : (
                <>
                  <Table
                    headings={[
                      {
                        width: "6%",
                        columnName: "Logo",
                        isSortable: "false",
                        Children: () => "",
                      },
                      {
                        columnName: "alias",
                        isSortable: true,
                        Children: ({ children }) => (
                          <span className="d-flex align-items-center h-100">
                            Push alias
                            {children}
                          </span>
                        ),
                      },
                      {
                        columnName: "push_from",
                        isSortable: true,
                        Children: ({ children }) => (
                          <span className="d-flex align-items-center h-100">
                            source in DWH
                            {children}
                          </span>
                        ),
                      },
                      {
                        columnName: "push_to",
                        isSortable: true,
                        Children: ({ children }) => (
                          <div className="d-flex justify-content-start gap-1 align-items-center w-100 h-100">
                            <span>destination</span>
                            <SearchJobs
                              onFilterChange={this.updateJobNameFilters}
                            />
                            {children}
                          </div>
                        ),
                      },
                      {
                        columnName: "last_started",
                        isSortable: true,
                        Children: ({ children }) => (
                          <span className="d-flex align-items-center h-100">
                            last started
                            {children}
                          </span>
                        ),
                      },
                      {
                        columnName: "last_ended",
                        isSortable: true,
                        Children: ({ children }) => (
                          <span className="d-flex align-items-center h-100">
                            last ended
                            {children}
                          </span>
                        ),
                      },
                      {
                        columnName: "status",
                        isSortable: false,
                        Children: () => "status",
                      },
                      {
                        width: "10%",
                        columnName: "actions",
                        isSortable: "false",
                        Children: () => "Actions",
                      },
                    ]}
                    rows={this.paginateArray(
                      this.state.DAGs?.filter((DAGtarget) =>
                        this.filterJobNames(DAGtarget)
                      )
                    ).map((dag, index) => ({
                      key: `${dag.id}${index}`,
                      columns: [
                        {
                          type: "image",
                          logo: dag.logo,
                        },
                        {
                          type: "text",
                          text: dag.alias,
                        },
                        {
                          type: "text",
                          text: dag.push_from,
                        },
                        {
                          type: "text",
                          text: dag.push_to,
                        },
                        {
                          type: "date",
                          date: dag.last_started,
                        },
                        {
                          type: "date",
                          date: dag.last_ended,
                        },
                        {
                          type: "status",
                          key: dag.status,
                        },
                        {
                          type: "component",
                          component: (
                            <HorizontalDropdownMenu
                              items={[
                                {
                                  tooltipTitle: "Trigger Push",
                                  variant: "no-outline-success",
                                  disabled: true,
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    this.triggerDAG(dag.id);
                                  },
                                  children: this.state.running_dags.includes(
                                    dag.id
                                  ) ? (
                                    <CircularProgress size={16} sx={{ color: "#2c49fc" }} />
                                  ) : (
                                    <PlayIcon />
                                  ),
                                },
                                {
                                  tooltipTitle: "Push Details",
                                  variant: "no-outline",
                                  disabled: dag.subtype === "Tableau",
                                  className: "me-1 mb-1",
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    navigate(`/jobs/push?edit_push=${dag.id}`);
                                  },
                                  children: (
                                    <SettingsIcon className="bigger-svg" />
                                  ),
                                },
                                {
                                  tooltipTitle: "Stop Push",
                                  variant: "no-outline-danger",
                                  className: "me-1 mb-1",
                                  disabled: true,
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    this.stopDAG(
                                      dag.id,
                                      this.state.dag_runs[dag.id][0].dag_run_id
                                    );
                                  },
                                  children: <StopIcon />,
                                },
                                {
                                  tooltipTitle: "Delete Push",
                                  variant: "no-outline-danger",
                                  className: "mb-1",
                                  disabled: false,
                                  onClick: (e) => {
                                    e.stopPropagation();
                                    this.toggleDeletepushmodal(
                                      dag.alias,
                                      dag.id
                                    );
                                  },
                                  children: <CancelIcon />,
                                },
                              ]}
                            />
                          ),
                        },
                      ],
                    }))}
                    sortedColumn={this.state.sortedColumn}
                    onSortColumn={this.onSortColumn}
                    isLoading={this.state.loading}
                  />
                  <TablePagination
                    currentPage={this.state.currentPage}
                    totalPages={this.calculateTotalPages()}
                    updatePageNum={(num) => this.setState({ currentPage: num })}
                    updatePerPage={(num) => this.setState({ perPage: num })}
                    activeTable={this.state.jobNameFilters}
                  />
                </>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <DeleteConnectorModal
          isShown={this.state.modal_delete_push}
          onHide={() => this.toggleDeletepushmodal()}
          onAction={() =>
            this.deletePush(
              this.state.deleting_push_alias,
              this.state.delete_push_id
            )
          }
          deleteData={{
            logo: this.state.delete_logo,
            deleting: this.state.deleting_push,
          }}
          text={{
            bold: this.state.deleting_push_alias,
            regular: "push",
          }}
        />

        <CSSTransition
          in={this.state.isShowingDagHistoryTable}
          classNames="form-tab"
          timeout={300}
          unmountOnExit
        >
          <DagHistory
            dagList={Boolean(this.state.DAGs.length) && this.state.DAGs}
            onClose={() => {
              this.setState({
                isShowingDagHistoryTable: false,
              });
              navigate("/jobs/push");
            }}
          />
        </CSSTransition>

        <CSSTransition
          in={this.state.isShowingCreatePushForm}
          classNames="form-tab"
          timeout={300}
          unmountOnExit
        >
          <PushCreatorForm
            dagsList={this.state?.DAGs}
            setSelectedPushData={this.setSelectedPushData}
            isEditingPush={new URLSearchParams(window.location.search).get(
              "edit_push"
            )}
            toggleCreatePushForm={this.toggleCreatePushForm}
            fetchAirflowDags={this.fetchAirflowDags}
            editorURL={this.state.dbt_url}
          />
        </CSSTransition>
      </div>
    );
  }
}

export default WithRouter(TenantContextComponent);
