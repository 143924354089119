import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "+",
  files_history: {
    "+": [{ date: "File Changes", author: "Just click to revert!" }],
  },
  files: { "+": "/* Write your SQL here! */" },
  selected_shas: {},
  unsaved_files: {},
  saved_commands: []
};

export const EditorSlice = createSlice({
  name: "editorSlice",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    addSavedCommand: (state, action) => {
      const { command } = action.payload;
      const isDuplicate = state.saved_commands.includes(command);
      if (!isDuplicate) {
        if (state.saved_commands.length === 5) {
          state.saved_commands.shift(); // Remove the first element
        }
        state.saved_commands.push(command); // Add the new command
      }
    },
    updateActiveTab: (state, actions) => {
      state.activeTab = actions.payload;
    },
    updateSelectedShas: (state, actions) => {
      const updatedState = actions.payload;
      state.selected_shas = Object.assign(state.selected_shas, updatedState);
    },
    removeSelectedShas: (state, actions) => {
      const removeState = actions.payload;
      state.selected_shas = Object.fromEntries(
        Object.entries(state.selected_shas).filter(
          ([key]) => key !== removeState
        )
      );
    },
    updateUnsavedFiles: (state, actions) => {
      const updatedState = actions.payload;
      state.unsaved_files = Object.assign(state.unsaved_files, updatedState);
    },
    removeUnsavedFiles: (state, actions) => {
      const removeState = actions.payload;
      state.unsaved_files = Object.fromEntries(
        Object.entries(state.unsaved_files).filter(
          ([key]) => key !== removeState
        )
      );
    },
    updateFilesHistory: (state, actions) => {
      const updatedState = actions.payload;
      state.files_history = Object.assign(state.files_history, updatedState);
    },
    insertFilesHistory: (state, actions) => {
      const stateValue = Object.values(actions.payload);
      const findState = Object.keys(
        Object.fromEntries(
          Object.entries(state.files_history).filter(([key]) =>
            key.includes(Object.keys(actions.payload))
          )
        )
      );
      state.files_history[findState].unshift(stateValue[0]);
    },
    removeFilesHistory: (state, actions) => {
      const removeState = actions.payload;
      state.files_history = Object.fromEntries(
        Object.entries(state.files_history).filter(
          ([key]) => key !== removeState
        )
      );
    },
    updateFiles: (state, actions) => {
      state.files = { ...state.files, ...actions.payload };
    },
    removeFiles: (state, actions) => {
      const removeState = actions.payload;
      state.files = Object.fromEntries(
        Object.entries(state.files).filter(([key]) => key !== removeState)
      );
    },
    reorderFiles: (state, action) => {
      const newFiles = action.payload.reduce((prev, cur) => ({...prev, [cur]: state.files[cur]}), {});

      return {
        ...state,
        files: newFiles,
      };
    },
  },
});

// Action creators generated for each case reducer function
export const {
  reset,
  updateActiveTab,
  updateScheduleButton,
  updateSelectedShas,
  removeSelectedShas,
  updateUnsavedFiles,
  removeUnsavedFiles,
  updateFilesHistory,
  insertFilesHistory,
  removeFilesHistory,
  updateFileHistory,
  insertFileHistory,
  removeFileHistory,
  updateFiles,
  removeFiles,
  saveExpandedList,
  reorderFiles,
  addSavedCommand
} = EditorSlice.actions;

export default EditorSlice.reducer;
