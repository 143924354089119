import React, { useState, useEffect } from "react";
import DatajoltSpinner from "../../components/global/DatajoltSpinner";
import { ChevronsRight, Minimize, Maximize } from "react-feather";
import _ from "lodash";
import { fetchDbtDocsService } from "../../services/editor";

const DbtDocsModal = ({ show, handleClose, dbtUrl, activeBranch }) => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (show) {
      const fetchDbtDocs = async () => {
        setContent(await fetchDbtDocsService(dbtUrl, activeBranch));
        setLoading(false);
      };
      fetchDbtDocs();
    }
  }, [show, activeBranch]);

  return (
    <div
      className={`sidebar-modal-wrapper ${isFullScreen && "fullscreen"}`}
      style={{
        height: window.innerHeight - 50,
        width: "50%",
      }}
    >
      {loading ? (
        <center className="connector-spinner" style={{ minHeight: "600px" }}>
          <br />
          <br />
          <DatajoltSpinner />
        </center>
      ) : (
        <section className="schedule-sidebar-content">
          <header
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>
              <ChevronsRight
                color="#438593"
                onClick={handleClose}
                role="button"
              />{" "}
              DBT Docs
            </h2>

            {isFullScreen ? (
              <Minimize role="button" onClick={() => setIsFullScreen(false)} />
            ) : (
              <Maximize role="button" onClick={() => setIsFullScreen(true)} />
            )}
          </header>
          <iframe srcDoc={content} width={`100%`} height={`100%`} />
        </section>
      )}
    </div>
  );
};

export default DbtDocsModal;
